import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";
import Globals from '../../config/globals';
import { Modal, message, Spin, Select, Alert, Table } from 'antd';

export default function SingleDateEventModal(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        showModal: props.showModal,
        modalTitle: '',
        buttonLabel: '',
        availableEventsList: [],
        memberID: props.memberID,
        eventEditMode: props.eventEditMode,
        dataLoaded: false,
        eventsListLoaded: false,
        selectedEvent: {}
    });


    const dateFormat = 'MM/DD/YYYY';

    useEffect(() => {
        setState({
            ...state,
            eventsListLoaded: false
        });

        GetAvailableEvents();
    }, [props]);

    useEffect(() => {
        var myModalTitle;
        var myButtonLabel;

        console.log(props.selectedEvent);

        if (state.eventsListLoaded === true) {
            if (props.eventEditMode === 'Edit') {
                myModalTitle = 'Update Member Date Event';
                myButtonLabel = 'Update Event';
                setValue('formFields.selectedListItem', props.selectedEvent);
                //setValue('formFields.dateSkillAwarded', mySelectedEvent.dateSkillAwarded);
                //setValue('formFields.skillExpirationDate', mySelectedEvent.skillExpirationDate);
            }
            else {
                myModalTitle = 'Add Member Date Event';
                myButtonLabel = 'Add Event';
                setValue('formFields.selectedListItem', {});
                //setValue('formFields.dateSkillAwarded', null);
                //setValue('formFields.skillExpirationDate', null);
            }

            setState({
                ...state,
                showModal: props.showModal,
                modalTitle: myModalTitle,
                buttonLabel: myButtonLabel,
                selectedEvent: props.selectedEvent,
                dataLoaded: true
            });
        }

    }, [state.eventsListLoaded]);

    function GetAvailableEvents() {
        Axios.get(`/api/GetAvailableDateEvents`, {
            params: {
                memberID: props.memberID
            }
        }).then(response => {
            setState({
                ...state,
                availableEventsList: response.data,
                eventsListLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    function UpdateDateEvent() {
        var postdata = {};

        if (props.eventEditMode === 'Edit') {
            postdata = {
                Members_ID: props.memberID,
                MemberDateEvents_ID: props.selectedEvent.memberDateEvents_ID,
                EventName: getValues().formFields.selectedListItem.eventName,
                Start_Date: getValues().formFields.selectedListItem.start_Date,
                End_Date: getValues().formFields?.selectedListItem.end_Date,
                CreatedBy: Globals.userInfo.kmrUserID
            }

            Axios.post(`/api/UpdateMemberDateEvent`, postdata
            ).then(response => {
                let updatedDateEventsList = response.data;

                toast.success('Member Date Event successfully updated');

                //now update the parent list
                const selectedIdx = updatedDateEventsList.findIndex(de => de.memberDateEvents_ID === props.selectedEvent.memberDateEvents_ID);

                //for graceful, animated close
                setTimeout(() => props.updateTableDisplay(updatedDateEventsList, selectedIdx), 1000);

                closeModal();

            }).catch(error => {
                toast.error('There was a problem updating Member Date Event');
            });
        }
        else {
            postdata = {
                Members_ID: props.memberID,
                EventName: getValues().formFields.selectedListItem.eventName,
                Start_Date: getValues().formFields.selectedListItem.start_Date,
                End_Date: getValues().formFields?.selectedListItem.end_Date
            }

            Axios.post(`/api/InsertMemberDateEvent`, postdata
            ).then(response => {
                let updatedDateEventsList = response.data.events;
                let newID = response.data.newID;

                console.log(newID);
                console.log(updatedDateEventsList);

                toast.success('Member Date Event successfully added');

                //now update the parent list
                const selectedIdx = updatedDateEventsList.findIndex(de => de.memberDateEvents_ID === newID);

                //for graceful, animated close
                setTimeout(() => props.updateTableDisplay(updatedDateEventsList, selectedIdx), 1000);

                closeModal();

            }).catch(error => {
                toast.error('There was a problem adding Member Date Event');
            });
        }
    }

    function closeModal() {
        //This prevents the modal from opening in the parent unless called upon again
        props.disableSingleDateEventModal();

        setState({
            ...state,
            showModal: false
        });
    }

    function openModal() {
        setState({
            ...state,
            showModal: true
        });
    }

    function onCompleteRequest() {
        console.log(getValues().formFields.selectedListItem.eventName);
        console.log(getValues().formFields.selectedListItem.start_Date);
        console.log(getValues().formFields.selectedListItem.end_Date);

        if (getValues().formFields.selectedListItem.eventName === '' ||
            getValues().formFields.selectedListItem.start_Date === '' ||
            getValues().formFields.selectedListItem.start_Date === null) {
            toast.error('All fields are required');

            return;
        }

        UpdateDateEvent();
    }

/*    console.log(getValues().formFields.selectedListItem);*/

    return (
        <>
            <Modal
                visible={state.showModal}
                title={state.modalTitle}
                onCancel={closeModal}
                footer={[
                    <button className="btn btn-submit" onClick={onCompleteRequest}>
                        {state.buttonLabel}
                    </button>,
                    <span>&nbsp;&nbsp;</span>,
                    <button className="btn btn-outline-primary" onClick={closeModal}>
                        Close
                    </button>
                ]}
            >
                <div className="row">
                    <div className="form-group col-lg-5">
                        <label className="form-label mb-0">Select New Event:</label>
                    </div>
                    <div className="form-group col-lg-7">
                        <select className="form-control-custom" {...register('formFields.selectedListItem.eventName')}>
                            <option></option>
                            {state.availableEventsList?.map((li, idx) => <option key={idx} value={li.kmrListItemID}>{li.listItemName}</option>)}
                        </select>
                    </div>

                    <div className="form-group col-lg-5">
                        <label className="form-label mb-0">Effective Date:</label>
                    </div>
                    <div className="form-group col-lg-4">
                        <Controller
                            name="formFields.selectedListItem.start_Date"
                            control={control}
                            setValue={setValue}
                            defaultValue={getValues().formFields?.selectedListItem.start_Date ? Moment(getValues().formFields.selectedListItem.start_Date).toDate() : null}
                            render={() =>
                                <DatePicker
                                    selected={getValues().formFields?.selectedListItem.start_Date ? Moment(getValues().formFields.selectedListItem.start_Date).toDate() : null}
                                    onChange={date => setValue('formFields.selectedListItem.start_Date', date)}
                                    className="form-control-custom"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="MM/dd/yyyy"
                                />
                            }
                        />
                    </div>
                    <div className="form-group col-lg-3"></div>

                    <div className="form-group col-lg-5">
                        <label className="form-label mb-0">End Date:</label>
                    </div>
                    <div className="form-group col-lg-4">
                        <Controller
                            name="formFields.selectedListItem.end_Date"
                            control={control}
                            setValue={setValue}
                            defaultValue={getValues().formFields?.selectedListItem.end_Date ? Moment(getValues().formFields.selectedListItem.end_Date).toDate() : null}
                            render={() =>
                                <DatePicker
                                    selected={getValues().formFields?.selectedListItem.end_Date ? Moment(getValues().formFields.selectedListItem.end_Date).toDate() : null}
                                    onChange={date => setValue('formFields.selectedListItem.end_Date', date)}
                                    className="form-control-custom"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="MM/dd/yyyy"
                                />
                            }
                        />
                    </div>
                    <div className="form-group col-lg-3"></div>
                </div>
            </Modal>
        </>
    );
}