import React, { useEffect, useState } from 'react';
import { Layout, Affix } from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    NotificationOutlined,
    MobileOutlined
} from '@ant-design/icons';

import { useThemeSwitcher } from "react-css-theme-switcher";

import './navigation.css';
import Globals from '../../config/globals';
import { confirm } from '@rickylandino/react-messages';
import { useNavigate } from "react-router-dom";

const AntSider = Layout.Sider;

export function Sider(props) {
    const navigate = useNavigate();
    const { currentTheme } = useThemeSwitcher();

    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        setCollapsed(props.collapsed);
    }, [props.collapsed]);


    function handleNavClick(e) {
        props.closeSider();
        switch (e.target.id) {
            case 'candidates':
                navigate("/candidates", { replace: true });
                break;
            case 'settings':
                navigate("/account-settings", { replace: true });
                break;
            default:
                break;
        }
    }

    return (
        <>

            <AntSider width={'100%'} defaultCollapsed collapsed={collapsed} onCollapse={(isCollapsed, type) => setCollapsed(isCollapsed)} theme={currentTheme}>

                <div>
                    <input className="ant-input" />
                </div>

                <div className="nav-item" id="dashboard" onClick={handleNavClick}>
                    <i className="fa-regular fa-user" id="dashboard" onClick={handleNavClick}></i>
                    &nbsp;
                    Dashboard
                </div>

                <div className="nav-item d-flex align-items-center" id="1" onClick={handleNavClick}>
                    <MobileOutlined className="fa-regular fa-user" id="1" onClick={handleNavClick} />
                    &nbsp;
                    1
                </div>


                <div className="nav-item d-flex align-items-center" id="2" onClick={handleNavClick}>
                    <NotificationOutlined id="2" onClick={handleNavClick} />
                    &nbsp;
                    2
                </div>
            </AntSider>
        </>
    );
}