import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useForm, Controller } from "react-hook-form";
import Globals from '../../config/globals';
import { Modal, message } from 'antd';
import { Select, Table } from 'antd';
import SingleDateEventModal from './SingleDateEventModal';

export default function MemberDateEventsTab(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        selectedMemberId: props.selectedMemberId,
        showSingleDateEventModal: false,
        dataLoaded: false,
        noteEditMode: 'Add',
        events: [],
        selectedEvent: {}
    });

    const dateFormat = 'MM/DD/YYYY';

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    useEffect(() => {
        if (state.events?.length > 0) {
            setState({ ...state, dataLoaded: true });
        }
    }, [state.events]);

    useEffect(() => {
        GetMemberDateEvents();
    }, [props]);

    function GetMemberDateEvents() {
        Axios.get(`/api/GetMemberDateEvents`, {
            params: {
                members_ID: props.selectedMemberId
            }
        }).then(response => {
            let eventsList = response.data;

            setState({
                ...state,
                events: eventsList,
                dataLoaded: true,
                showSingleDateEventModal: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function hidePane() {
        ////now update the parent list
        //let members = props.members;

        //const selectedIdx = members.findIndex(m => m.member.members_ID === getValues().formFields.member.members_ID);
        //members[selectedIdx] = getValues().formFields;

        ////for graceful, animated close
        //setTimeout(() => props.updateTableDisplay(members, selectedIdx), 1000);

        setState({
            ...state,
            showSingleDateEventModal: false
        });
    }

    const columns = [
        {
            dataIndex: 'memberDateEvents_ID',
            title: 'memberDateEvents_ID',
            key: 'memberDateEvents_ID',
            hidden: true
        }, {
            dataIndex: 'start_Date',
            title: 'Start Date',
            key: 'start_Date',
            width: 120,
            render: (start_Date) => {
                return (
                    <div>
                        {start_Date === null ? '' : Moment(dateWithNoTimezone(start_Date)).format("L")}
                    </div>
                );
            }
        }, {
            dataIndex: 'end_Date',
            title: 'End Date',
            key: 'end_Date',
            width: 120,
            render: (end_Date) => {
                return (
                    <div>
                        {end_Date === null ? '' : Moment(dateWithNoTimezone(end_Date)).format("L")}
                    </div>
                );
            }
        }, {
            dataIndex: 'createdBy',
            title: 'Created By',
            key: 'createdBy',
            sort: true,
            width: 175
        }, {
            dataIndex: 'eventName',
            title: 'Event Name',
            key: 'eventName',
            sort: true
        }, {
            title: 'Edit Event',
            key: 'action',
            render: (record) => (
                <i id="editItem" className="fas fa-edit fa-1x ml-3 text-center hover" onClick={(e) => handleEditEvent(e, record)}></i>
            ),
            width: 100,
        }
    ].filter(item => !item.hidden);

    function handleAddNewEvent() {
        setState({
            ...state,
            selectedItemId: null,
            selectedIdx: null,
            eventEditMode: 'Add',
            selectedEvent: null,
            showSingleDateEventModal: true
        });
    }

    function handleEditEvent(e, record) {
        setState({
            ...state,
            selectedItemId: record.memberDateEvents_ID,
            //selectedIdx: rowIndex,
            eventEditMode: 'Edit',
            selectedEvent: record,
            showSingleDateEventModal: true
        });
    }


    function DeleteCustomerNote(itemId) {
        //let postdata = {};

        //postdata.itemId = itemId;
        //Axios.post('/api/DeleteCustomerNote', postdata
        //).then(response => {
        //    let filterednotes = state.notes.filter(item => item.itemId !== itemId)

        //    setState({
        //        ...state,
        //        notes: filterednotes,
        //        dataLoaded: true,
        //        showNoteDetailsSlider: false
        //    });

        //    toast.success('Note deleted successfully');
        //}).catch(error => {
        //    console.log(error);
        //});
    }

    function updateTableDisplay(events, idxToDirectTo = null) {
        setSelectedRowKeys([events[idxToDirectTo].memberDateEvents_ID]);

        setState({
            ...state,
            events: [...events],
            selectedItemId: events[idxToDirectTo].memberDateEvents_ID,
            showSingleDateEventModal: false
        });
    }

    function disableSingleDateEventModal() {
        setState({
            ...state,
            showSingleDateEventModal: false
        });
    }

    async function reloadDateEventsFromDB() {
        GetMemberDateEvents();
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    return (
        <Fragment>
            <div className="form-group col-12 pb-3">
                <span className="color-dark-blue"><label className="form-label">Member Date Events</label></span>
                <button className="btn btn-submit margin-left-15 float-right" onClick={handleAddNewEvent}>Add Event</button>
            </div>
            <div>
                {state.dataLoaded &&
                    <Fragment>
                        {state.events.length === 0 ?
                            <div style={{ fontSize: '18px', color: 'red' }}>No Notes Found</div>
                            :
                            <Fragment>
                                <div>
                                    <Table className="custom-ant-selection"
                                        rowKey={item => item.memberDateEvents_ID}
                                        rowSelection={rowSelection}
                                        bordered dataSource={state.events}
                                        columns={columns}
                                        pagination={{
                                            defaultPageSize: 10,
                                            showSizeChanger: true,
                                            pageSizeOptions: ['10', '25', '50', '100'],
                                            showTotal: (total, range) => (
                                                <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                    Showing {range[0]}-{range[1]} of {total}
                                                </span>
                                            )
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: () => {
                                                    console.log("hi", record);

                                                    let selRows = [record.memberDateEvents_ID];
                                                    setSelectedRowKeys([...selRows]);
                                                },
                                                onDoubleClick: (e) => {
                                                    handleEditEvent(e, record);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </Fragment>
                        }
                    </Fragment>
                }
            </div>

            {state.showSingleDateEventModal &&
                <SingleDateEventModal showModal={state.showSingleDateEventModal} eventEditMode={state.eventEditMode} events={state.events} selectedEvent={state.selectedEvent}
                    updateTableDisplay={updateTableDisplay} memberID={props.selectedMemberId} disableSingleDateEventModal={disableSingleDateEventModal} />
            }
        </Fragment>
    );
}