import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";
import Globals from '../../config/globals';
import { Modal, message, Select } from 'antd';

export default function SingleNoteModal(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const { Option } = Select;

    const [state, setState] = useState({
        showModal: false,
        noteEditMode: props.noteEditMode,
    });


    const dateFormat = 'MM/DD/YYYY';

    useEffect(() => {
        console.log(props.selectedNote);

        if (props.selectedItemId !== null) {
            setValue('formFields', props.selectedNote);
        }
        else {
            var note_date = new Date();
            setValue('formFields.dateCreated', note_date);
            setValue('formFields.note', '');
            setValue('formFields.createdBy', Globals.userInfo.firstName + ' ' + Globals.userInfo.lastName);
        }

        setState({
            ...state,
            showModal: props.showModal,
            mode: props.mode,
        });
    }, [props]);

    function UpdateNote() {
        if (props.noteEditMode === 'Edit') {
            let postdata = {};

            postdata.Members_ID = props.selectedMemberId;
            postdata.MemberNotes_ID = props.selectedItemId;
            postdata.note = getValues().formFields?.note;
            postdata.DateCreated = getValues().formFields?.dateCreated;

            Axios.post('/api/UpdateMemberNote', postdata
            ).then(response => {
                let updatedNotesList = response.data

                toast.success('Note changes successfully saved');

                //now update the parent list
                const selectedIdx = updatedNotesList.findIndex(n => n.memberNotes_ID === props.selectedNote.memberNotes_ID);

                //for graceful, animated close
                setTimeout(() => props.updateTableDisplay(updatedNotesList, selectedIdx), 1000);

                closeModal();

            }).catch(error => {
                console.log(error);
            });
        }
        else {
            let postdata = {};
            postdata.Members_ID = props.selectedMemberId;
            postdata.note = getValues().formFields?.note;
            postdata.DateCreated = getValues().formFields?.dateCreated;
            postdata.CreatedBy = parseInt(Globals.userInfo.kmrUserID);

            Axios.post('/api/InsertMemberNote', postdata
            ).then(response => {
                let updatedNotesList = response.data.notes;
                let newID = response.data.newID;

                console.log(newID);
                console.log(updatedNotesList);

                toast.success('Member Note successfully added');

                //now update the parent list
                const selectedIdx = updatedNotesList.findIndex(n => n.memberNotes_ID === newID);

                console.log(selectedIdx);

                //for graceful, animated close
                setTimeout(() => props.updateTableDisplay(updatedNotesList, selectedIdx), 1000);

                closeModal();
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function closeModal() {
        //now update the parent list
        if (props.mode === 'Edit') {
            let notes = props.notes;

            const selectedIdx = notes.findIndex(n => n.memberNotes_ID === props.selectedItemId);
            notes[selectedIdx] = getValues().formFields;

            //for graceful, animated close
            //setTimeout(() => props.updateTableDisplay(notes, selectedIdx), 1000);
            props.updateTableDisplay(notes, selectedIdx)
        }
        else {
            setTimeout(() => props.reloadNotesFromDB(), 1000);
        }

        setState({
            ...state,
            showModal: false
        });
    }

    function handleSave() {
        UpdateNote();

        //if (props.selectedItemId !== null) {
        //    UpdateMemberNote();
        //}
        //else {
        //    InsertMemberNote();
        //}
    }

    function InsertMemberNote() {
        let postdata = {};
        postdata.Members_ID = props.selectedMemberId;
        postdata.note = getValues().formFields?.note;
        postdata.DateCreated = getValues().formFields?.dateCreated;
        postdata.CreatedBy = parseInt(Globals.userInfo.kmrUserID);

        Axios.post('/api/InsertMemberNote', postdata
        ).then(response => {
            closeModal();
            toast.success('Note successfully added');

        }).catch(error => {
            console.log(error);
        });
    }

    function UpdateMemberNote() {
        let postdata = {};

        postdata.MemberNotes_ID = props.selectedItemId;
        postdata.note = getValues().formFields?.note;
        postdata.DateCreated = getValues().formFields?.dateCreated;

        Axios.post('/api/UpdateMemberNote', postdata
        ).then(response => {
            closeModal();
            toast.success('Note changes successfully saved');

        }).catch(error => {
            console.log(error);
        });
    }

    console.log(getValues().formFields?.createdBy);

    return (
        <>
            <Modal
                visible={state.showModal}
                title="Member Note"
                onCancel={closeModal}
                footer={[
                    <button className="btn btn-submit" onClick={handleSave} key="1">
                        Save Note
                    </button>,
                    <span key="2">&nbsp;&nbsp;</span>,
                    <button className="btn btn-outline-primary" onClick={closeModal} key="3">
                        Close
                    </button>
                ]}
            >
                <div className="row">
                    <div className="col-lg-4">
                        <label className="form-label mb-0">Date Entered:</label>
                    </div>
                    <div className="form-group col-lg-8">
                        <label className="form-label mb-0">{Moment(dateWithNoTimezone(getValues().formFields?.dateCreated)).format("L")}</label>
                    </div>

                    <div className="col-lg-4">
                        <label className="form-label mb-0">Entered By:</label>
                    </div>
                    <div className="form-group col-lg-8">
                        <label className="form-label mb-0">{getValues().formFields?.createdBy}</label>
                    </div>

                    <div className="col-lg-1">
                        <label className="form-label mb-0">Note:</label>
                    </div>
                    <div className="form-group col-lg-12">
                        <textarea rows="4" {...register('formFields.note')} className="form-control-custom" />
                    </div>
                </div>
            </Modal>
        </>
    );
}