import { BehaviorSubject } from 'rxjs';
import Axios from '../config/axios';

export const activeRoute = new BehaviorSubject('');

export const userInfo = new BehaviorSubject({});
export const userPermissions = new BehaviorSubject({});

export const realUserInfoWhileImpersonationIsOn = new BehaviorSubject(null);
export const imitationMode = new BehaviorSubject(false);

export function insertSingleUser(postdata) {
    return Axios.post(`/api/InsertSingleUser`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateUser(postdata) {
    return Axios.post(`/api/UpdateUser`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateUserPassword(postdata) {
    return Axios.post(`/api/UpdateUserPassword`, postdata
    ).then(response => response.data).catch(error => error);
}


export function hashwordConversion(postdata) {
    return Axios.post(`/api/HashwordConversion`, postdata
    ).then(response => response.data).catch(error => error);
}

export function sendResetPasswordLink(postdata) {
    return Axios.post('/api/SendResetPasswordLink', postdata)
        .then(response => response.data)
        .catch(error => error);
}

export function updateUserProfilePicture(postdata) {
    return Axios.post('/api/UpdateUserProfilePicture', postdata)
        .then(response => response.data)
        .catch(error => error);
}