import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from 'react-maskedinput';
import Moment from 'moment';
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";
import Globals from '../../config/globals';
import { Modal, message, Select } from 'antd';
import statesList from '../../helpers/StatesList';
import { useNavigate, useLocation } from "react-router-dom";

export default function AddNewMemberSlider(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        showPane: props.showPane,
        selectedMemberId: '',
        selectedIdx: '',
        formFields: {},
        members: [],
        states: statesList,
        showMemberNotesSlider: false,
        showMemberDateEventsSlider: false,
        showClassificationModal: false,
        classificationType: ''
    });

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const [modalInfo, setModalInfo] = useState({
        showmodal: false,
        content: {}
    });

    const dateFormat = 'MM/DD/YYYY';

    useEffect(() => {
        setState({
            ...state,
            states: statesList
        });
    }, []);

    useEffect(() => {
        if (props.showPane) {
            //setValue("formFields.member.ssn", '043990399');
            //setValue("formFields.member.cardClientNo", '1234567890');
            //setValue("formFields.member.lastName", 'LANDINO');
            //setValue("formFields.member.firstName", 'DAVID');
            //setValue("formFields.member.address1", '42 MADISON WAY');
            //setValue("formFields.member.city", 'SOUTH WINDSOR');
            //setValue("formFields.member.state", 'CT');
            //setValue("formFields.member.zip", '06074');
            //setValue("formFields.member.dateOfBirth", '03/08/1964');
            //setValue("formFields.member.internationalInitiationDate", '03/05/2023');
            //setValue("formFields.member.localInitiationDate", '03/05/2023');
            //setValue("formFields.member.cellPhone", '860-965-9916');
            //setValue("formFields.member.currentLocal", '777');
            //setValue('formFields.member.emailAddress', 'dlandino@automated-concepts.com');

            //setState({
            //    ...state
            //});
        }
    }, [props]);

    function hidePane() {
        //now update the parent list
        let members = props.members;

        //console.log(members);
        //console.log(getValues().formFields.members_ID);

        const selectedIdx = members.findIndex(m => m.member.members_ID === getValues().formFields.member.members_ID);
        members[selectedIdx] = getValues().formFields;

        //for graceful, animated close
        setTimeout(() => props.updateTableDisplay(members, selectedIdx), 1000);

        setState({
            ...state,
            showPane: false,
            showMemberNotesSlider: false,
            showMemberDateEventsSlider: false,
            showClassificationModal: false
        });
    }

    function onEditClassification(classificationType) {
        if (classificationType === 'WorkZones' || classificationType === 'Skills') {
            toast.info(classificationType + " Coming Soon!");

            return;
        }

        setState({
            ...state,
            classificationType: classificationType,
            showMemberNotesSlider: false,
            showMemberDateEventsSlider: false,
            showClassificationModal: true
        });
    }

    const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)

    function formatSSN(val) {
        if (val === undefined) {
            return '';
        }

        val = val.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{2})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        return val;
    }  	

    function onApplyChanges() {
        let formFields = state.formFields;
        formFields.contractorStatus = props.selectedContractorStatus;

        if (screenDataOK() === false) {
            return;
        }

        //console.log(getValues().formFields.member);

        Axios.post(`/api/AddNewMember`, getValues().formFields.member
        ).then(response => {
            //now update the parent list
            if (response.data) {
                //console.log(response.data.newMember);

                navigate("/member-dashboard", { state: { selectedMemberId: response.data.newID, selectedMemberName: response.data.newMember.member.lastName + ', ' + response.data.newMember.member.firstName, pageNumber: tableControl.page, members: state.members } });

                toast.success("New Member Successfully Added");
            }
        }).catch(error => {
            toast.error("Problem Encountered Adding New Member");

            console.log(error);
        });
    }

    function onNotesClick() {
        //openModal();

        setState({
            ...state,
            showMemberDateEventsSlider: false,
            showClassificationModal: false,
            showMemberNotesSlider: true
        });
    }

    function onDateEventsClick() {
        //toast.info("Date Events Coming Soon!");

        setState({
            ...state,
            showMemberNotesSlider: false,
            showClassificationModal: false,
            showMemberDateEventsSlider: true
        });
    }

    function closeModal() {
        setModalInfo({
            ...modalInfo,
            showmodal: false
        });
    }

    function openModal() {
        setModalInfo({
            ...modalInfo,
            showmodal: true
        });
    }

    function updateClassification(classificationType, newValue) {
        console.log(classificationType);
        console.log(newValue);

        switch (classificationType) {
            case 'Status':
                setValue('formFields.status', newValue.itemDescription);
                setValue('formFields.statusEffectiveDate', newValue.appliesFrom);

                break;
            case 'RateClass':
                setValue('formFields.rateClass', newValue.itemDescription);
                setValue('formFields.rateClassEffectiveDate', newValue.appliesFrom);

                break;
            case 'Trade':
                setValue('formFields.trade', newValue.itemDescription);
                setValue('formFields.tradeEffectiveDate', newValue.appliesFrom);

                break;
            case 'TradeLevel':
                setValue('formFields.tradeLevel', newValue.itemDescription);
                setValue('formFields.tradeLevelEffectiveDate', newValue.appliesFrom);

                break;
            default:
            // code block
        }

        setState({
            ...state,
            showClassificationModal: false
        });
    }

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    function screenDataOK() {
        let message = '';
        let myHrsTot = 0;

        console.log(isValidDate(getValues().formFields?.member.dateOfBirth));
        console.log(isValidDate(getValues().formFields?.member.internationalInitiationDate));

        if (getValues().formFields.member.ssn === '') {
            message += '&bull;&nbsp;SSN #</br>';
        }
        if (getValues().formFields.member.cardClientNo === '') {
            message += '&bull;&nbsp;UA Card #</br>';
        }
        if (getValues().formFields?.member.lastName === '') {
            message += '&bull;&nbsp;Last Name</br>';
        }
        if (getValues().formFields?.member.firstName === '') {
            message += '&bull;&nbsp;First Name</br>';
        }
        if (getValues().formFields?.member.dateOfBirth === '' || getValues().formFields?.member.dateOfBirth === null || isValidDate(getValues().formFields?.member.dateOfBirth) === false) {
            message += '&bull;&nbsp;Date of Birth</br>';
        }
        if (getValues().formFields?.member.address1 === '') {
            message += '&bull;&nbsp;Address 1</br>';
        }
        if (getValues().formFields?.member.city === '') {
            message += '&bull;&nbsp;City</br>';
        }
        if (getValues().formFields?.member.state === '') {
            message += '&bull;&nbsp;State</br>';
        }
        if (getValues().formFields?.member.zip === '') {
            message += '&bull;&nbsp;Zip</br>';
        }
        if (getValues().formFields?.member.internationalInitiationDate === '' || getValues().formFields?.member.internationalInitiationDate === null || isValidDate(getValues().formFields?.member.internationalInitiationDate) === false) {
            message += '&bull;&nbsp;Initiation Date</br>';
        }
        if (getValues().formFields?.member.localInitiationDate === '' || getValues().formFields?.member.localInitiationDate === null || isValidDate(getValues().formFields?.member.localInitiationDate) === false) {
            message += '&bull;&nbsp;Joined Local</br>';
        }
        if (getValues().formFields?.member.cellPhone === '') {
            message += '&bull;&nbsp;Cell Phone</br>';
        }

        if (message != '') {
            message = "<strong>Mandatory fields missing or invalid data specified</strong><br />" + message;
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return false;
        }
        else {
            return true;
        }
    }

    return (
        <>
            <SlidingPane
                className='some-custom-class w-90'
                overlayClassName='showCard'
                isOpen={state.showPane}
                title='Add New Member'
                onRequestClose={hidePane}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div>
                        {!state.loading &&
                            <Fragment>
                            {/*    */}{/*<div className="panel-hdr">*/}
                            {/*    */}{/*    {state.selectedRequestId && (<h2>{state.formFields.contactName}</h2>)}*/}
                            {/*    */}{/*    {!state.selectedRequestId && (<h2>New Market Recovery Request</h2>)}*/}
                            {/*    */}{/*</div>*/}
                            <Card className="local777Card">
                                    <Card.Header><span>Member Information&nbsp;&nbsp;&nbsp;* Indicates Required Fields</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="form-group col-lg-1">
                                                <label className="form-label"><span style={{ color: 'red' }} >* </span>SSN #</label>
                                                <input type="text" className="form-control-custom" {...register('formFields.member.ssn')} />
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <label className="form-label"><span style={{ color: 'red' }} >* </span>Card #</label>
                                                <input type="text" className="form-control-custom" {...register('formFields.member.cardClientNo')} />
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label"><span style={{ color: 'red' }} >* </span>Last Name</label>
                                                <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.lastName')} />
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label"><span style={{ color: 'red' }} >* </span>First Name</label>
                                                <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.firstName')} />
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <label className="form-label">Middle Initial</label>
                                                <input type="text" maxLength="1" className="form-control-custom text-uppercase" {...register('formFields.member.middleInitial')} />
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <label className="form-label">Suffix</label>
                                                <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.suffix')} />
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <label className="form-label"><span style={{ color: 'red' }} >* </span>Date of Birth</label>
                                                <Controller
                                                    name="formFields.member.dateOfBirth"
                                                    control={control}
                                                    setValue={setValue}
                                                    defaultValue={getValues().formFields?.member.dateOfBirth ? Moment(getValues().formFields.member.dateOfBirth).toDate() : null}
                                                    render={() =>
                                                        <DatePicker
                                                            selected={getValues().formFields?.member.dateOfBirth ? Moment(getValues().formFields.member.dateOfBirth).toDate() : null}
                                                            onChange={date => setValue('formFields.member.dateOfBirth', date)}
                                                            className="form-control-custom"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            dateFormat="MM/dd/yyyy"
                                                            customInput={
                                                                <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" />
                                                            }
                                                            preventOpenOnFocus={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <label className="form-label">Age</label><br />
                                                <label className="form-label"><b>{getValues().formFields?.member.dateOfBirth ? getAge(getValues().formFields.member.dateOfBirth) : ''}</b></label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-2">
                                                <label className="form-label"><span style={{ color: 'red' }} >* </span>Address 1</label>
                                                <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.address1')} />
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Address 2</label>
                                                <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.address2')} />
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label"><span style={{ color: 'red' }} >* </span>City</label>
                                                <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.city')} />
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <label className="form-label"><span style={{ color: 'red' }} >* </span>State</label>
                                                <select className="form-control-custom text-uppercase" {...register('formFields.member.state')}>
                                                    <option></option>
                                                    {state.states.map((state, idx) => <option key={idx} value={state.abbreviation}>{state.abbreviation}</option>)}
                                                </select>
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <label className="form-label"><span style={{ color: 'red' }} >* </span>Zip</label>
                                                <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.zip')} />
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <label className="form-label">Country</label>
                                                <select className="form-control-custom" {...register('formFields.member.country')}>
                                                    <option></option>
                                                    <option>USA</option>
                                                    <option>Unknown</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-1">
                                                <label className="form-label"><span style={{ color: 'red' }} >* </span>Init. Date</label>
                                                <Controller
                                                    name="formFields.member.internationalInitiationDate"
                                                    control={control}
                                                    setValue={setValue}
                                                    defaultValue={getValues().formFields?.member.internationalInitiationDate ? Moment(getValues().formFields.member.internationalInitiationDate).toDate() : null}
                                                    render={() =>
                                                        <DatePicker
                                                            selected={getValues().formFields?.member.internationalInitiationDate ? Moment(getValues().formFields.member.internationalInitiationDate).toDate() : null}
                                                            onChange={date => setValue('formFields.member.internationalInitiationDate', date)}
                                                            className="form-control-custom"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            dateFormat="MM/dd/yyyy"
                                                            customInput={
                                                                <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" />
                                                            }
                                                            preventOpenOnFocus={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <label className="form-label"><span style={{ color: 'red' }} >* </span>Joined Local</label>
                                                <Controller
                                                    name="formFields.member.localInitiationDate"
                                                    control={control}
                                                    setValue={setValue}
                                                    defaultValue={getValues().formFields?.member.localInitiationDate ? Moment(getValues().formFields.member.localInitiationDate).toDate() : null}
                                                    render={() =>
                                                        <DatePicker
                                                            selected={getValues().formFields?.member.localInitiationDate ? Moment(getValues().formFields.member.localInitiationDate).toDate() : null}
                                                            onChange={date => setValue('formFields.member.localInitiationDate', date)}
                                                            className="form-control-custom"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            dateFormat="MM/dd/yyyy"
                                                            customInput={
                                                                <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" />
                                                            }
                                                            preventOpenOnFocus={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <label className="form-label">Current Local</label>
                                                <input type="text" className="form-control-custom" {...register('formFields.member.currentLocal')} />
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <label className="form-label">Prior Local</label>
                                                <input type="text" className="form-control-custom" {...register('formFields.member.priorLocal')} />
                                            </div>
                                            {/*<div className="form-group col-lg-1">*/}
                                            {/*    <label className="form-label">2nd Prior Local</label>*/}
                                            {/*    <input type="text" className="form-control-custom" {...register('formFields.member.secondPriorLocal')} />*/}
                                            {/*</div>*/}
                                            <div className="form-group col-lg-1">
                                                <label className="form-label">EB Badge No.</label>
                                                <input type="text" className="form-control-custom" {...register('formFields.member.ebBadgeNo')} />
                                            </div>
                                        </div>
                                </Card.Body>
                            </Card>

                            <div className="row">
                                <div className="col col-lg-4 col-12">
                                    <Card className="local777Card" style={{ minHeight: '400px' }}>
                                        <Card.Header><span>Classifications</span></Card.Header>
                                        <Card.Body>
                                            <div className="row">
                                                <div class="text-center" style={{ color: 'red' }}>
                                                    You must click 'Apply Changes' to save new member before accessing this information
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col col-lg-4 col-12">
                                    <Card className="local777Card">
                                        <Card.Header><span>Contact Information</span></Card.Header>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="form-group col-lg-4"><label className="form-label">Email</label></div>
                                                <div className="form-group col-lg-8"><input type="text" className="inline-text-box" {...register('formFields.member.emailAddress')} /></div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-4"><label className="form-label"><span style={{ fontSize: '18px', color: 'red' }} >* </span>Cell Phone</label></div>
                                                <div className="form-group col-lg-4"><input type="text" className="inline-text-box" {...register('formFields.member.cellPhone')} /></div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-4"><label className="form-label">Emergency Phone</label></div>
                                                <div className="form-group col-lg-4"><input type="text" className="inline-text-box" {...register('formFields.member.emergencyPhone')} /></div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-4"><label className="form-label">Emergency Contact</label></div>
                                                <div className="form-group col-lg-6"><input type="text" className="inline-text-box" {...register('formFields.member.emergencyContact')} /></div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-4"><label className="form-label">Work Phone</label></div>
                                                <div className="form-group col-lg-4"><input type="text" className="inline-text-box" {...register('formFields.member.workPhone')} /></div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-4"><label className="form-label">Evening Phone</label></div>
                                                <div className="form-group col-lg-4"><input type="text" className="inline-text-box" {...register('formFields.member.eveningPhone')} /></div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-4"><label className="form-label">Alt Phone</label></div>
                                                <div className="form-group col-lg-4"><input type="text" className="inline-text-box" {...register('formFields.member.alternative')} /></div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-4"><label className="form-label">Beneficiary</label></div>
                                                <div className="form-group col-lg-8"><input type="text" className="inline-text-box" {...register('formFields.member.beneficiary')} /></div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col col-lg-4 col-12">
                                    <Card className="local777Card">
                                        <Card.Header><span>Census Information</span></Card.Header>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="form-group col-lg-4"><label className="form-label">Gender</label></div>
                                                <div className="form-group col-lg-4">
                                                    <select className="form-control-custom" {...register('formFields.member.gender')}>
                                                        <option></option>
                                                        <option>Male</option>
                                                        <option>Female</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-4"><label className="form-label">Marital Status</label></div>
                                                <div className="form-group col-lg-4">
                                                    <select className="form-control-custom" {...register('formFields.member.maritalStatus')}>
                                                        <option></option>
                                                        <option>Divorced</option>
                                                        <option>Married</option>
                                                        <option>Single</option>
                                                        <option>Unknown</option>
                                                        <option>Widowed</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-4"><label className="form-label">Race</label></div>
                                                    <div className="form-group col-lg-4">
                                                        <select className="form-control-custom" {...register('formFields.member.race')}>
                                                            <option></option>
                                                            <option>African American</option>
                                                            <option>Alaska Native</option>
                                                            <option>Asian</option>
                                                            <option>Black</option>
                                                            <option>Caucasian</option>
                                                            <option>FE</option>
                                                            <option>Hawaiian/Pac Isl</option>
                                                            <option>Hispanic</option>
                                                            <option>Mid East Indian</option>
                                                            <option>Native American</option>
                                                            <option>Other</option>
                                                            <option>Unknown</option>
                                                            <option>White</option>
                                                        </select>
                                                    </div>
                                            </div>
                                                <div className="row">
                                                    <div className="form-group col-lg-4"><label className="form-label">Secondary Race</label></div>
                                                    <div className="form-group col-lg-4">
                                                        <select className="form-control-custom" {...register('formFields.member.secondaryRace')}>
                                                            <option></option>
                                                            <option>African American</option>
                                                            <option>Alaska Native</option>
                                                            <option>Asian</option>
                                                            <option>Black</option>
                                                            <option>Caucasian</option>
                                                            <option>FE</option>
                                                            <option>Hawaiian/Pac Isl</option>
                                                            <option>Hispanic</option>
                                                            <option>Mid East Indian</option>
                                                            <option>Native American</option>
                                                            <option>Other</option>
                                                            <option>Unknown</option>
                                                            <option>White</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            <div className="row">
                                                <div className="form-group col-lg-4"><label className="form-label">Veteran</label></div>
                                                <div className="form-group col-lg-4">
                                                    <select className="form-control-custom" {...register('formFields.member.veteran')}>
                                                        <option></option>
                                                        <option>Yes</option>
                                                        <option>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <Card className="local777Card">
                                <Card.Header><span>Member Skills</span></Card.Header>
                                    <Card.Body>
                                        <div class="text-left" style={{ fontSize: '18px', color: 'red' }}>
                                            You must click 'Apply Changes' to save new member before accessing this information
                                        </div>
                                </Card.Body>
                            </Card>

                            </Fragment>
                        }
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <div class="text-left">
                            <span><button className="btn btn-submit" onClick={onApplyChanges}>Apply Changes</button></span>
                            <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                        </div>                   
                    </div>
                </div>
            </SlidingPane>

            <Modal
                visible={modalInfo.showmodal}
                title="Set Market Request Completed"
                onCancel={closeModal}
                footer={[

                    <span>&nbsp;&nbsp;</span>,
                    <button className="btn btn-outline-primary" onClick={closeModal}>
                        Close
                    </button>
                ]}
            >
                <div className="row">
                    <div className="col-lg-1">
                        <label className="form-label mb-0">Notes:</label>
                    </div>

                </div>
            </Modal>
        </>
    );
}