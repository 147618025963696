import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";
import Globals from '../../config/globals';
import { Modal, message, Table, Space, Select } from 'antd';
import ClassificationModal from './ClassificationModal';
import { getMemberPaymentInformation } from '../../services/PaymentsService';

export default function ClassificationSlider(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        showModal: props.showModal,
        classificationType: '',
        friendlyClassificationType: '',
        classificationEditMode: 'Add',
        selectedClassificationItem: {},
        buttonLabel: '',
        sliderTitle: '',
        itemsList: [],
        historyItemsList: [],
        memberID: props.memberID,
        dataLoaded: false,
        showSingleClassification: false,
        appliesToColHidden: false,
        chargesList: [],
        mostRecentAppliesFromDate: ''
    });  

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    const dateFormat = 'MM/DD/YYYY';

    useEffect(() => {
        setValue('formFields.appliesFrom', '');
        setValue('formFields.selectedListItem', '');

        GetClassificationModalEntries();
    }, [props]);

    function GetClassificationModalEntries() {
        var friendlyClassificationType;
        let myAppliesToColHidden = false;

        switch (props.classificationType) {
            case 'Status':
                friendlyClassificationType = 'Status';
                myAppliesToColHidden = true;
                break;
            case 'RateClass':
                friendlyClassificationType = 'Rate Class';
                break;
            case 'Trade':
                friendlyClassificationType = 'Trade';
                break;
            case 'TradeLevel':
                friendlyClassificationType = 'Trade Level';
                break;
            default:
            // code block
        }

        setState({
            ...state,
            historyItemsList: [],
            itemsList: [],
            dataLoaded: false,
            listItemsLoaded: false
        });

        Axios.get(`/api/GetClassificationHistoryEntries`, {
            params: {
                memberID: props.memberID,
                classificationType: props.classificationType
            }
        }).then(response => {
            //if we are working with Rate Class then we need to also get all existing payments
            var chargesList = [];
            if (props.classificationType === 'RateClass') {
                getMemberPaymentInformation(props.memberID).then(data => {
                    chargesList = data.chargesList;

                    let mostRecentAppliesFromDate = response.data.historyItemsList[0].appliesFrom;

                    setState({
                        ...state,
                        historyItemsList: response.data.historyItemsList,
                        sliderTitle: 'Member ' + friendlyClassificationType + ' History',
                        buttonLabel: 'Add New ' + friendlyClassificationType,
                        friendlyClassificationType: friendlyClassificationType,
                        appliesToColHidden: myAppliesToColHidden,
                        showPane: true,
                        dataLoaded: true,
                        showSingleClassification: false,
                        chargesList: chargesList,
                        mostRecentAppliesFromDate
                    });
                }).catch(error => {
                    console.log(error);
                });
            }
            else {
                let mostRecentAppliesFromDate = response.data.historyItemsList[0].appliesFrom;
                setState({
                    ...state,
                    historyItemsList: response.data.historyItemsList,
                    sliderTitle: 'Member ' + friendlyClassificationType + ' History',
                    buttonLabel: 'Add New ' + friendlyClassificationType,
                    friendlyClassificationType: friendlyClassificationType,
                    appliesToColHidden: myAppliesToColHidden,
                    showPane: true,
                    dataLoaded: true,
                    showSingleClassification: false,
                    mostRecentAppliesFromDate
                });
            }
            
        }).catch(error => {
            console.log(error);
        });
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    //function UpdateClassification() {
    //    let postdata = {
    //        memberID: props.memberID,
    //        selectedListItem: getValues().formFields.selectedListItem,
    //        classificationType: props.classificationType,
    //        appliesFromDate: formatDate(getValues().formFields?.appliesFrom),
    //        CreatedBy: Globals.userInfo.kmrUserID
    //    }

    //    Axios.post(`/api/UpdateClassification`, postdata
    //    ).then(response => {
    //        let newClassification = response.data;

    //        toast.success('Member ' + props.classificationType + ' successfully updated');

    //        props.updateClassification(props.classificationType, newClassification);

    //        closeModal();

    //    }).catch(error => {
    //        toast.error('There was a problem updating Member' + props.classificationType);
    //    });
    //}

    const columns = [
        {
            dataIndex: 'itemID',
            title: 'itemID',
            key: 'itemID',
            hidden: true
        }, {
            dataIndex: 'itemCode',
            title: 'itemCode',
            key: 'itemCode',
            hidden: true
        }, {
            dataIndex: 'itemDescription',
            title: 'Item Description',
            key: 'itemDescription',
            sort: true
        }, {
            dataIndex: 'appliesFrom',
            title: 'Effective Date From',
            key: 'appliesFrom',
            width: 175,
            render: (appliesFrom) => {
                return (
                    <div>
                        {appliesFrom === null ? '' : Moment(dateWithNoTimezone(appliesFrom)).format("L")}
                    </div>
                );
            }
        }, {
            dataIndex: 'appliesTo',
            title: 'Effective Date To',
            key: 'appliesTo',
            width: 175,
            hidden: state.appliesToColHidden,
            render: (appliesTo) => {
                return (
                    <div>
                        {appliesTo === null ? '' : Moment(dateWithNoTimezone(appliesTo)).format("L")}
                    </div>
                );
            }
        //}, {
        //    dataIndex: 'itemStatus',
        //    title: 'Status',
        //    key: 'itemStatus',
        //    render: (itemStatus) => {
        //        return (
        //            <div>
        //                {itemStatus === 'Active' ? <div style={{ color: 'green', fontWeight: 'bold' }}>Active</div> : <div style={{ color: 'red', fontWeight: 'bold' }}>Expired</div>}
        //            </div>
        //        );
        //    },
        //    width: 100,
        }, {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (record) => (
                record.appliesFrom === state.mostRecentAppliesFromDate ? <i id="deleteItem" className="fa-solid fa-trash-can fa-1x ml-3 text-center hover" style={{ color: 'red' }} onClick={(e) => handleDeleteClassification(e, record)}></i> : ''
            ),
            width: 100,
        //}, {
        //    title: 'Item Details',
        //    key: 'action',
        //    render: (record) => (
        //        <i id="editItem" className="fas fa-edit fa-1x ml-3 text-center hover" onClick={(e) => handleEditClassification(e, record)}></i>
        //    ),
        //    width: 100,
        }
    ].filter(item => !item.hidden);

    function hidePane() {
        //now update the parent list
        //for graceful, animated close
        //setTimeout(() => props.updateSkills(state.skills), 1000);

        setState({
            ...state,
            showPane: false,
            showSingleClassification: false
        });
    }

    function closeModal() {
        setState({
            ...state,
            showModal: false,
            showSingleClassification: false
        });
    }

    function openModal() {
        setState({
            ...state,
            showModal: true
        });
    }

    function updateTableDisplay(skills, idxToDirectTo) {
        GetClassificationModalEntries();
    }

    function disableModal() {
        setState({
            ...state,
            showSingleClassification: false
        });
    }

    const onSelectChange = (newSelectedRowKeys) => {
        //console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
		renderCell: () => "", // Render nothing inside
    };

    function handleEditClassification(e, record) {
        setState({
            ...state,
            classificationEditMode: 'Edit',
            selectedClassificationItem: record,
            showSingleClassification: true
        });
    }

    function handleDeleteClassification(e, record) {
        switch (props.classificationType) {
            case 'Status':
                
                break;
            case 'RateClass':
                DeleteMemberRateClass(record);
                break;
            case 'Trade':

                break;
            case 'TradeLevel':

                break;
            default:
            // code block
        }
    }

    function DeleteMemberRateClass(record) {
        var i;
        var j;

        //first get the list of months that the RateClass record covers
        var dtAppliesFrom;
        var dtAppliesTo;
        var paymentFound = false;

        //Lets check to see if there are any existing payments in the range of dates for this RateClass
        dtAppliesFrom = new Date(dateWithNoTimezone(record.appliesFrom));
        if (record.appliesTo === null) {
            dtAppliesTo = new Date();
        }
        else {
            dtAppliesTo = new Date(dateWithNoTimezone(record.appliesTo));
        }

        let chargesList = state.chargesList;

        for (i = 0; i < chargesList[i].chargesDetailsList.length; i++) {
            for (j = 0; j < chargesList[i].chargesDetailsList.length; j++) {
                if (dateCheck(dtAppliesFrom.toString(), dtAppliesTo.toString(), new Date(dateWithNoTimezone(chargesList[i].chargesDetailsList[j].chargeDateApplicableFrom)).toString())) {
                    paymentFound = true;
                    break;
                }
            }
            if (paymentFound === true) {
                break;
            }
        }

        if (paymentFound === true) {
            toast.error('At least one payment exists that uses this Rate Class. Please contact your System Administrator to help with this.');
            return;
        }

        confirm({
            title: "You are about to permanently delete this Rate Class",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let postdata = {};

                postdata.itemId = record.itemID;
                postdata.memberID = props.memberID;

                Axios.post('/api/DeleteMemberRateClass', postdata
                ).then(response => {
                    let mostRecentAppliesFromDate = response.data[0].appliesFrom;

                    setState({
                        ...state,
                        historyItemsList: response.data,
                        mostRecentAppliesFromDate,
                        dataLoaded: true
                    });

                    toast.success('Rate Class deleted successfully');
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function dateCheck(from, to, check) {
        var fromDate, toDate, checkDate;
        fromDate = Date.parse(from);
        toDate = Date.parse(to);
        checkDate = Date.parse(check);

        if ((checkDate <= toDate && checkDate >= fromDate)) {
            return true;
        }
        return false;
    }

    function handleAddNewClassificationItem() {
        setState({
            ...state,
            classificationEditMode: 'Add',
            selectedClassificationItem: null,
            showSingleClassification: true
        });
    }

    return (
        <>
            <SlidingPane
                className='some-custom-class w-50'
                overlayClassName='showCard'
                isOpen={state.showPane}
                title={state.sliderTitle}
                onRequestClose={hidePane}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div className="form-group col-12 pb-3">
                        {/*<span className="color-dark-blue"><label className="form-label">Member Notes</label></span>*/}
                        <button className="btn btn-submit margin-left-15 float-right" onClick={handleAddNewClassificationItem}>{state.buttonLabel}</button>
                    </div>
                    <div>
                        {state.dataLoaded &&
                            <Fragment>
                                {state.historyItemsList.length === 0 ?
                                    <div style={{ fontSize: '18px', color: 'red' }}>No {state.friendlyClassificationType} History Found For This Member</div>
                                    :
                                    <Fragment>
                                        <div className="col-lg-12">
                                            &nbsp;
                                        </div>
                                        <div className="form-group col-lg-12">
                                            {state.dataLoaded &&
                                                <Table className="custom-ant-selection" rowKey={item => item.itemID} rowSelection={rowSelection} bordered dataSource={state.historyItemsList} columns={columns} pagination={{ pageSize: 5 }}
                                                    onRow={(record) => {
                                                        return {
                                                            onClick: () => {
                                                                //console.log("hi", record)
                                                                let selRows = [record.itemID];
                                                                setSelectedRowKeys([...selRows]);

                                                                //setState({
                                                                //    ...state,
                                                                //    showSingleClassification: false
                                                                //});
                                                            },
                                                            onDoubleClick: (e) => {
                                                                //handleEditClassification(e, record);
                                                            }
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-outline-primary margin-left-15" onClick={hidePane}>Close</button>
                    </div>
                </div>
            </SlidingPane>

            {state.showSingleClassification &&
                <ClassificationModal showModal={state.showSingleClassification} classificationType={props.classificationType} selectedClassificationItem={state.selectedClassificationItem}
                    friendlyClassificationType={state.friendlyClassificationType} memberID={props.memberID} updateTableDisplay={updateTableDisplay} classificationEditMode={state.classificationEditMode}
                    disableModal={disableModal} historyItemsList={state.historyItemsList} chargesList={state.chargesList}
                />
            }

        </>
    );
}