import Axios from '../config/axios';

export function getVersion() {
    return Axios.get('/api/GetVersion').then(response => response.data).catch(error => error);
}

export function insertNewVersion(postdata) {
    return Axios.post(`/api/InsertNewVersion`, postdata
    ).then(response =>
        response.data
    ).catch(error => {
        console.log(error);
    });
}