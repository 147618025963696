import React, { useState, useEffect, useContext, useRef } from 'react';
import { Popover, List, Modal, Popconfirm, Table, Select, Divider } from 'antd';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import AddableTable from '../../helpers/AddableTable';
import { useForm, Controller } from "react-hook-form";
import Moment from 'moment';
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import Axios from '../../config/axios';
import Globals from '../../config/globals';
import { addMemberPayment, renderReceipt } from '../../services/PaymentsService';

import { toast } from '@rickylandino/react-messages';

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

Date.isLeapYear = function (year) {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
};

Date.getDaysInMonth = function (year, month) {
    return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

Date.prototype.isLeapYear = function () {
    return Date.isLeapYear(this.getFullYear());
};

Date.prototype.getDaysInMonth = function () {
    return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
};

Date.prototype.addMonths = function (value) {
    var n = this.getDate();
    this.setDate(1);
    this.setMonth(this.getMonth() + value);
    this.setDate(Math.min(n, this.getDaysInMonth()));
    return this;
};

export default function PaymentsSlider(props) {
    const { register, getValues, setValue, control, watch } = useForm();
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        showPane: false,
        sliderTitle: "Add New Payment"
    });

    const [dataSource, setDataSource] = useState([{
        billedAdjustment: 'MISCELLANEOUS',
        chargeAmount: 0,
        comments: ''
    }]);
    const [paymentSource, setPaymentSource] = useState([{
        paymentType: "Cash",
        paymentAmount: "",
        paymentID: "",
        dateReceived: null,
        paymentDate: new Date(),
        checkDate: null,
        key: 1
    }]);
    const [duesSource, setDuesSource] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalDuesAmount, setTotalDuesAmount] = useState(0);
    const [reinstatement, setReinstatement] = useState(false);

    const [miscCreditApplied, setMiscCreditApplied] = useState(0);
    const [amountToCarryForward, setAmountToCarryForward] = useState(0);

    const [rateClassChangeApplied, setRateClassChangeApplied] = useState(0);
    const [rateClassChangeAmountToCarryForward, setRateClassChangeAmountToCarryForward] = useState(0);

    const [rateClassChangeCreditApplied, setRateClassChangeCreditApplied] = useState(0);
    const [rateClassChangeCreditAmountToCarryForward, setRateClassChangeCreditAmountToCarryForward] = useState(0);

    const [miscAdjustment, setMiscAdjustment] = useState(null);

    const [modifiedPaidThroughDate, setModifiedPaidThroughDate] = useState(null);

    const [dontIncludeOnCashSheet, setDontIncludeOnCashSheet] = useState(false);

    const [paymentTableMessage, setPaymentTableMessage] = useState(null);

    const handleDelete = (key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
    };


    const handleDeletePaymentSource = (key) => {
        const newData = paymentSource.filter((item) => item.key !== key);
        setPaymentSource(newData);
    };

    useEffect(() => {
        if (props.showPane) {
            Axios.get(`/api/GetFeeInformation`, {
                params: {
                    members_ID: props.memberID
                }
            }).then(response => {
                setDuesSource(response.data);
            }).catch(error => {
                console.log(error);
            });
        }

        setState({
            ...state,
            showPane: props.showPane
        });
        
    }, [props]);

    useEffect(() => {
        var ta = 0;
        var duesRow = null;

        duesSource.forEach(ds => {
            if (ds.feeName === "DUES") {
                duesRow = ds;
                ta += (ds.feeRate * ds.monthsPaid);
            } else if (ds.feeName === "REINSTATEMENT") {
                if (ds.pay) {
                    ta += ds.feeRate
                }

                setReinstatement(ds.pay);
            } else if (ds.feeName === "Rate Class Change") {
                ta += parseFloat(ds.pay || 0);
                if (ds.pay) {
                    setRateClassChangeApplied(parseInt(ds.pay || 0));
                    setRateClassChangeAmountToCarryForward(ds.feeRate + parseInt(ds.pay));
                }
            } else {

                ta -= parseFloat(ds.pay || 0);

                if (ds.feeName === 'MISCELLANEOUS' && ds.pay) {
                    setMiscCreditApplied(parseInt(ds.pay || 0));
                    setAmountToCarryForward((ds.feeRate * -1) - parseInt(ds.pay));
                } else if (ds.feeName === 'Rate Class Change Credit' && ds.pay) {
                    setRateClassChangeCreditApplied(parseInt(ds.pay || 0));
                    setRateClassChangeCreditAmountToCarryForward((ds.feeRate * -1) - parseInt(ds.pay));
                }
            }
        });

        if (duesRow) {
            let newPaidThruDate = modifyPaidThruDate(duesRow);
            setModifiedPaidThroughDate(newPaidThruDate);
        }
        
        setTotalDuesAmount(ta);
    }, [duesSource]);

    useEffect(() => {
        let ta = totalDuesAmount;

        if (dataSource[0]?.chargeAmount) {
            ta += dataSource[0].chargeAmount;

            setMiscAdjustment(dataSource[0]);
        } else {
            setMiscAdjustment(null);
        }

        setTotalAmount(ta);

    }, [totalDuesAmount, dataSource])

    function hidePane() {

        setTimeout(() => props.hidePane(), 1000);

        setState({
            ...state,
            showPane: false
        });
    }

    function modifyPaidThruDate(row) {
        if (row.paidThroughDate) {
            const dateCopy = dateWithNoTimezone(row.paidThroughDate);

            dateCopy.addMonths(parseInt(row.monthsPaid));

            dateCopy.setDate(dateCopy.getDaysInMonth());

            return dateCopy;
        } else {
            return null;
        }
    }

    const miscFormatterColumns = [
        {
            title: 'Date',
            dataIndex: 'createdDateTime',
            render: (cell, row) => {
                return (
                    Moment(cell).format("L")
                );
            }
        },
        {
            title: 'Amount',
            dataIndex: 'chargeAmount',
            render: (cell, row) => {
                return (
                    currencyFormatter.format(cell * -1)
                );
            }
        },
        {
            title: 'Comments',
            dataIndex: 'comments'
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy'
        }
    ];

    const popoverFormatter = (type, cell, data) => {
        switch (type) {
            case 'dues':
                return (
                    <List
                        size="small"
                        header={<strong>Month Charge</strong>}
                        bordered
                        dataSource={data}
                        renderItem={(item) => <List.Item>{item}: {currencyFormatter.format(cell)}</List.Item>}
                    />);
            case 'misc':
                return (<Table style={{width: 500}} dataSource={data} columns={miscFormatterColumns} rowKey={(record) => record.createdDateTime} pagination={false} scroll={{ y: 500 }} />);
            default:
                break;
        }
    }

    const duesColumns = [
        {
            title: 'Fee Name',
            dataIndex: 'feeName',
            editable: false
        },
        {
            title: 'Fee Rate',
            dataIndex: 'feeRate',
            editable: false,
            render: (cell, row) => {
                var data = [];
                var type = '';
                switch (row.feeName) {
                    case 'DUES':
                        data = row.months ? row.months : [];
                        type = 'dues';
                        break;
                    case 'MISCELLANEOUS':
                        data = row.miscCharges;
                        type = 'misc';
                        break;
                    default:
                        break;
                }
                return (
                    <div>
                        {currencyFormatter.format(cell)} &nbsp;
                        <Popover style={{maxWidth: '300px'}} placement="right" content={() => popoverFormatter(type, cell, data)}>
                            <i className="fa-regular fa-circle-question candidate-text hover"></i>
                        </Popover>
                    </div>
                );
            }
        },
        {
            title: 'Months Paid',
            dataIndex: 'monthsPaid',
            editable: true,
            type: 'dropdown',
            options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            render: (cell, row, index) => {
                return (
                    index === 0 ? cell : null
                );
            }
        },
        {
            title: 'Fee Amount',
            dataIndex: 'feeAmount',
            editable: false,
            render: (cell, row) => {
                return (
                    currencyFormatter.format(row.feeRate * row.monthsPaid)
                );
            }
        },
        {
            title: 'Apply Amount',
            dataIndex: 'applyAmount',
            editable: false,
            render: (cell, row) => {
                return (
                    currencyFormatter.format(row.feeRate * row.monthsPaid)
                );
            }
        },
        {
            title: 'Pay',
            dataIndex: 'pay',
            editable: true, 
            type: [{ feeName: 'REINSTATEMENT', type: 'checkbox' }, { feeName: 'MISCELLANEOUS', type: 'dollar', maxValue: 'feeRate' }, { feeName: 'Rate Class Change', type: 'dollar', maxValue: 'feeRate' }]
        },
        {
            title: 'Paid Through Date',
            dataIndex: 'paidThroughDate',
            render: (cell, row) => {
                if (cell) {
                    //const dateCopy = dateWithNoTimezone(cell);

                    //dateCopy.addMonths(parseInt(row.monthsPaid));

                    //dateCopy.setDate(dateCopy.getDaysInMonth());

                    const dateCopy = modifyPaidThruDate(row);

                    //setModifiedPaidThroughDate(dateCopy);

                    //let lastDayOfMonthDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                    return Moment(dateCopy).format("L")
                } else {
                    return null;
                }
            },
            editable: false
        },
    ];

    const billingColumns = [
        {
            title: 'Billed Adjustment',
            dataIndex: 'billedAdjustment'
        },
        {
            title: 'Amount',
            dataIndex: 'chargeAmount',
            type: 'dollar',
            editable: true,
        },
        {
            title: 'Notes',
            dataIndex: 'comments',
            editable: true,
            type: 'input'
        }
    ];

    const paymentColumns = [
        {
            title: <span>Payment Type <span className="text-danger">*</span></span>,
            dataIndex: 'paymentType',
            editable: true,
            type: 'dropdown',
            options: ['', 'Cash', 'Money Order', 'Check', 'Credit Card - Machine', 'COVID 18', 'Adjustment']
        },
        {
            title: 'Amount',
            dataIndex: 'paymentAmount',
            type: 'dollar',
            editable: true,
        },
        {
            title: 'Payment ID',
            dataIndex: 'paymentID',
            editable: true,
            type: 'input'
        },
        {
            title: <span>Date Received <span className="text-danger">*</span></span>,
            dataIndex: 'dateReceived',
            editable: true,
            type: 'date'
        },
        {
            title: <span>Deposit Date <span className="text-danger">*</span></span>,
            dataIndex: 'paymentDate',
            editable: true,
            type: 'date'
        },
        {
            title: 'Check Date',
            dataIndex: 'checkDate',
            editable: true,
            type: 'date'
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_, record) =>
                paymentSource.length >= 1 ? (
                    <i className="far fa-trash-alt text-danger" style={{ fontSize: '1.2rem' }} onClick={() => handleDeletePaymentSource(record.key)}></i>
                ) : null,
            ignore: true
        },
    ];

    function handleIncludeOnCashSheet(e) {
        setDontIncludeOnCashSheet(e.target.checked);
    }

    async function handleSavePayment() {
        toast.loading('Saving...', { key: 'loading' });

        let memberCharge = {
            Members_ID: props.memberID,
            PaidthruDate: modifiedPaidThroughDate,
            PostDate: new Date(),
            ChargeAmount: totalAmount,
            CashSheet: dontIncludeOnCashSheet ? "N": "Y",
            Comments: getValues().formFields.paymentNotes,
            CreatedBy: Globals.userInfo.kmrUserID,
            IsPosted: 0,
            IsVoided: 0
        }

        var totalPayments = 0;

        var missingFields = false;

        paymentSource.forEach(ps => {
            if (!ps.paymentType || !ps.dateReceived || !ps.paymentDate) {
                missingFields = true;
            }

            ps.checkDate = ps.checkDate || null;

            totalPayments += ps.paymentAmount || 0;
        });

        if (missingFields) {
            toast.destroy('loading');
            toast.error("Please fill in all required fields");
        } else if (totalPayments < totalAmount) {
            setPaymentTableMessage(<span className="text-danger">Amount does not match</span>);
            toast.destroy('loading');
        } else {
            setPaymentTableMessage(null);
            let postdata = {
                memberCharge,
                oldPaidThruDate: props.member?.member?.paidthruDate,
                miscCreditApplied,
                amountToCarryForward,
                rateClassChangeApplied,
                rateClassChangeAmountToCarryForward,
                rateClassChangeCreditApplied,
                rateClassChangeCreditAmountToCarryForward,
                reinstatement,
                memberPayments: paymentSource,
                miscAdjustedPayment: miscAdjustment
            }

            console.log(postdata);

            var memberCharges_ID = await addMemberPayment(postdata);

            toast.destroy('loading');
            if (memberCharges_ID > 0) {
                renderReceipt(memberCharges_ID);
                toast.success("Payment Saved");

                props.refreshList();
                setState({
                    ...state,
                    showPane: false
                });
                setTimeout(() => props.hidePane(), 200);
            } else {
                toast.error("Something went wrong");
            }
        }
    }

    console.log(paymentSource);

    return (
        <>
            <SlidingPane
                className='some-custom-class w-75'
                overlayClassName='showCard'
                isOpen={state.showPane}
                title={state.sliderTitle}
                onRequestClose={hidePane}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div className="row justify-content-between">
                        <div className="col">
                            <strong>Paid through Date: {Moment(dateWithNoTimezone(props.member?.member?.paidthruDate)).format("L")}</strong>
                        </div>

                        <div className="col text-center">
                            <button className="btn btn-submit me-3">Add Manual Fees</button>
                        </div>
                    </div>

                    <Divider />

                    <div>
                        <AddableTable columns={duesColumns} dataSource={duesSource} updateDataSource={(ds) => setDuesSource(ds)} hideAdd={true} from='dues' />
                    </div>

                    <Divider />

                    <div className="row">
                        <div className="col-6">
                            <strong>Total Amount</strong>
                        </div>
                        <div className="col-6">
                            <strong>{currencyFormatter.format(totalDuesAmount)}</strong>
                        </div>
                    </div>

                    <Divider />

                    <div>
                        <AddableTable columns={billingColumns} dataSource={dataSource} updateDataSource={(ds) => setDataSource(ds)} hideAdd={true} hidePagination={true} />
                    </div>

                    <Divider />

                    <div>
                        <AddableTable columns={paymentColumns} dataSource={paymentSource} updateDataSource={(ds) => setPaymentSource(ds)} tableMessage={paymentTableMessage} />
                    </div>

                    <Divider />

                    <div className="row">
                        <div className="col-lg-3">
                            <label className="form-label">Total Amount</label>
                            <input type="text" className="form-control-custom" value={totalAmount} />
                        </div>

                        <div className="col-lg-6">
                            <label className="form-label">Payment Notes</label>
                            <textarea type="text" className="form-control-custom" {...register('formFields.paymentNotes')} />
                        </div>

                        <div className="col-lg-3">
                            <div className="custom-control custom-checkbox">
                                <label className="form-label"> Don't Include On Cash Sheet</label>
                                <br />
                                <input type="checkbox" className="custom-control-input" onChange={handleIncludeOnCashSheet} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer" style={{height: '9%'}}>
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-submit me-3" onClick={handleSavePayment}>Save Payment</button>
                        <button className="btn btn-outline-primary margin-left-15" onClick={hidePane}>Close</button>
                    </div>
                </div>
            </SlidingPane>
        </>
    );
}