import Axios from '../config/axios';

export async function addMemberPayment(postdata) {

    const ret = await Axios.post('/api/AddMemberPayment', postdata);

    return ret.data;
}

export function renderReceipt(memberCharges_ID) {
    const q = Axios.defaults.baseURL + "api/RenderReceipt";

    var form = document.createElement("form");
    form.target = "_blank";
    form.method = "POST";
    form.action = q;
    form.style.display = "none";

    var input = document.createElement("input");
    input.type = "hidden";
    input.name = "memberCharges_ID";
    input.value = memberCharges_ID;
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
}

export function getMemberPaymentInformation(members_ID) {
    return Axios.get("/api/GetMemberPaymentInformation", {
        params: {
            members_ID
        }
    }).then(response => response.data).catch(error => error);
}