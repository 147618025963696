import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useForm, Controller } from "react-hook-form";
import Globals from '../../config/globals';
import { Select, Table, Modal, message } from 'antd';
import SingleSkillModal from './SingleSkillModal';

export default function MemberSkillsSlider(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        selectedMemberId: props.memberID,
        showPane: props.showPane,
        showSingleSkillModal: false,
        dataLoaded: false,
        skillEditMode: 'Add',
        skills: [],
        filteredskills: [],
        selectedSkill: {}
    });

    const dateFormat = 'MM/DD/YYYY';

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    useEffect(() => {
        if (state.skills?.length > 0) {
            setState({ ...state, dataLoaded: true });
        }
    }, [state.skills]);

    useEffect(() => {
        GetMemberSkills();
    }, [props]);

    function GetMemberSkills() {
        Axios.get(`/api/GetMemberSkills`, {
            params: {
                members_ID: props.memberID
            }
        }).then(response => {
            let skillsList = response.data;

            setState({
                ...state,
                skills: skillsList,
                filteredskills: skillsList,
                dataLoaded: true,
                showPane: true,
                showSingleSkillModal: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function hidePane() {
        //now update the parent list
        //for graceful, animated close
        setTimeout(() => props.updateSkills(state.skills), 1000);

        setState({
            ...state,
            showPane: false,
            showSingleSkillModal: false
        });
    }

    const columns = [
        {
            dataIndex: 'memberSkills_ID',
            title: 'memberSkills_ID',
            key: 'memberSkills_ID',
            hidden: true
        }, {
            dataIndex: 'skillCode',
            title: 'Skill Name',
            key: 'skillCode',
            width: 100
        }, {
            dataIndex: 'skillDescription',
            title: 'Skill Description',
            key: 'skillDescription',
            sort: true
        }, {
            dataIndex: 'dateSkillAwarded',
            title: 'Skill Awarded',
            key: 'dateSkillAwarded',
            width: 120,
            render: (dateSkillAwarded) => {
                return (
                    <div>
                        {dateSkillAwarded === null ? '' : Moment(dateWithNoTimezone(dateSkillAwarded)).format("L")}
                    </div>
                );
            }
        }, {
            dataIndex: 'skillExpirationDate',
            title: 'Exp. Date',
            key: 'skillExpirationDate',
            width: 120,
            render: (skillExpirationDate) => {
                return (
                    <div>
                        {skillExpirationDate === null ? '' : Moment(dateWithNoTimezone(skillExpirationDate)).format("L")}
                    </div>
                );
            }
        }, {
            dataIndex: 'dateCreated',
            title: 'Date Created',
            key: 'dateCreated',
            width: 120,
            render: (dateCreated) => {
                return (
                    <div>
                        {dateCreated === null ? '' : Moment(dateWithNoTimezone(dateCreated)).format("L")}
                    </div>
                );
            }
        }, {
            dataIndex: 'skillStatus',
            title: 'Status',
            key: 'skillStatus',
            render: (skillStatus) => {
                return (
                    <div>
                        {skillStatus === 'Active' ? <div style={{ color: 'green', fontWeight: 'bold' }}>Active</div> : <div style={{ color: 'red', fontWeight: 'bold' }}>Expired</div>}
                    </div>
                );
            },
            width: 100,
        }, {
            title: 'Item Details',
            key: 'action',
            render: (record) => (
                <i id="editItem" className="fas fa-edit fa-1x ml-3 text-center hover" onClick={(e) => handleEditSkill(e, record)}></i>
            ),
            width: 100,
        }
    ].filter(item => !item.hidden);

    function handleAddNewSkill() {
        setState({
            ...state,
            selectedItemId: null,
            selectedIdx: null,
            skillEditMode: 'Add',
            selectedSkill: null,
            showSingleSkillModal: true
        });
    }

    function handleEditSkill(e, record) {
        console.log(record);

        setState({
            ...state,
            selectedItemId: record.memberSkills_ID,
            skillEditMode: 'Edit',
            selectedSkill: record,
            showSingleSkillModal: true
        });
    }

    function updateTableDisplay(skills, idxToDirectTo) {
        setValue('formFields.skillStatus', 'All');

        setState({
            ...state,
            skills: [...skills],
            filteredskills: [...skills],
            selectedItemId: skills[idxToDirectTo].memberSkills_ID,
            showSingleSkillModal: false
        });
    }

    function disableSingleSkillModal() {
        setState({
            ...state,
            showSingleSkillModal: false
        });
    }

    function handleStatusChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        //console.log(value);
        //console.log(getValues().formFields.skillStatus);
        let myfilteredskillslist = [];

        //only add the skills who's status matches the selected filter
        if (value === 'All') {
            myfilteredskillslist = state.skills;
        }
        else {
            state.skills.forEach(function (item, index) {
                if (item.skillStatus === value) {
                    myfilteredskillslist.push(item)
                }
            });
        }

        setState({
            ...state,
            filteredskills: myfilteredskillslist,
            showSingleSkillModal: false
        });
    }

    const onSelectChange = (newSelectedRowKeys) => {
        //console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    return (
        <>
            <SlidingPane
                className='some-custom-class w-75'
                overlayClassName='showCard'
                isOpen={state.showPane}
                title='Member Skills'
                onRequestClose={hidePane}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div className="form-group col-12 pb-3">
                        <button className="btn btn-submit margin-left-15 float-right" onClick={handleAddNewSkill}>Add Skill</button>
                    </div>

                    <div>
                        <div className="form-group col-lg-2">
                            <label className="form-label">Select Skill Status</label>
                            <select className="form-control-custom" {...register('formFields.skillStatus')} onChange={handleStatusChange}>
                                <option>All</option>
                                <option>Active</option>
                                <option>Expired</option>
                            </select>
                        </div>
                        <div className="row"></div>

                        {state.dataLoaded &&
                            <Fragment>
                                {state.filteredskills.length === 0 ?
                                    <div style={{ fontSize: '18px', color: 'red' }}>No Skills Found Matching The Selected Criteria</div>
                                    :
                                    <Fragment>
                                        <div>
                                            <Table className="custom-ant-selection" rowKey={item => item.memberSkills_ID} rowSelection={rowSelection} bordered dataSource={state.filteredskills} columns={columns} pagination={{ pageSize: 5 }}
                                                onRow={(record) => {
                                                    return {
                                                        onClick: () => {
                                                            let selRows = [record.memberSkills_ID];
                                                            setSelectedRowKeys([...selRows]);
                                                        },
                                                        onDoubleClick: (e) => {
                                                            handleEditSkill(e, record);
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-outline-primary margin-left-15" onClick={hidePane}>Close</button>
                    </div>
                </div>
            </SlidingPane>

            {state.showSingleSkillModal &&
                <SingleSkillModal showModal={state.showSingleSkillModal} skills={state.skills} selectedSkill={state.selectedSkill}
                    memberID={props.memberID} updateTableDisplay={updateTableDisplay} skillEditMode={state.skillEditMode} disableSingleSkillModal={disableSingleSkillModal} />
            }

        </>
    );
}