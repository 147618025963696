import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import { Login } from './components/Auth/Login';
import { Dashboard } from './components/Dashboard/Dashboard';
import { Layout } from './components/Layout';
import { Route, useLocation, Routes } from 'react-router-dom';
import MemberManager from './components/Members/MemberManager';
import { toast } from '@rickylandino/react-messages';
import Globals from './config/globals';
import { getVersion, insertNewVersion } from './services/CachingService';
import { validateUserByUid } from './services/TokenService';
import { activeRoute, userInfo, userPermissions } from './services/UsersService';
import MemberDashboard from './components/Members/MemberDashboard';

function App(props) {
    const location = useLocation();
    const [uInfo, setUInfo] = useState({});
    const [refreshCache, setRefreshCache] = useState(false);

    useEffect(() => {
        getVersionInformation();

        if (window.sessionStorage.getItem("uid")) {
            console.log("???")
            validateUserByUid(window.sessionStorage.getItem("uid")).then(data => {
                Globals.userInfo = data.user;
                userInfo.next(data.user);
                Globals.bearer = data.token;
            });
        }
    }, []);

    useEffect(() => {
        activeRoute.next(location.pathname);
    }, [location]);

    async function getVersionInformation() {
        let basePath = window.location.href;
        var baseUrl = basePath.replace(/\/+$/, '') + '/' + 'meta.json';

        const response = await fetch(baseUrl, {
            cache: 'no-store'
        });

        const meta = await response?.json();

        getVersion().then(data => {
            if (data && !data.isAxiosError) {

                if (`${meta?.version}` !== data?.cacheVersion?.version) {
                    toast.loading("Update in progress");
                    setRefreshCache(true);

                    let postdata = {
                        version: meta.version,
                        webVersion: data.cacheVersion?.webVersion
                    }

                    insertNewVersion(postdata).then(data2 => {
                        props.emptyCache();
                    });
                }
                else if (window?.localStorage?.APP_VERSION && (window.localStorage.APP_VERSION !== data.cacheVersion?.version && !props?.isLatestVersion)) {
                    toast.loading("Update in progress");
                    setRefreshCache(true);

                    props.emptyCache();
                }

            } else {
                toast.warning("Cache Refresh Failed");
            }
        }).catch(e => {
            toast.warning("Cache Refresh Failed");
        });
    }

    return (
        <div className="App main fade-in h-100">
            {(location.pathname === '/') ?
                (
                    <Routes>
                        <Route path="/" element={<Login />} />
                    </Routes>
                ) :
                <Layout>
                    <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/member-manager" element={<MemberManager />} />
                        <Route path="/members" element={<MemberManager />} />
                        <Route path="/member-dashboard" element={<MemberDashboard />} />
                    </Routes>
                </Layout>
            }
        </div>
    );
}

export default App;
