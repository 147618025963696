import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import 'antd/dist/antd.css';
import './css/global.css';
import './css/forms.css';
import './css/buttons.css';
import './css/custom.css';
import './css/app.bundle.css';
import './css/card.css';
import './css/antd-override.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const themes = {
    dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

root.render(
    <BrowserRouter>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme="light">
            <App />
        </ThemeSwitcherProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
