import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useForm, Controller } from "react-hook-form";
import Globals from '../../config/globals';
import {Select, Table } from 'antd';
import SingleNoteModal from './SingleNoteModal';

export default function MemberNotesTab(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        selectedMemberId: props.selectedMemberId,
        showSingleNoteModal: false,
        dataLoaded: false,
        noteEditMode: 'Add',
        notes: [],
        selectedNote: {}
    });

    const dateFormat = 'MM/DD/YYYY';

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    useEffect(() => {
        if (state.notes?.length > 0) {
            setState({ ...state, dataLoaded: true });
        }
    }, [state.notes]);

    useEffect(() => {
        GetMemberNotes();
    }, [props]);

    function GetMemberNotes() {
        Axios.get(`/api/GetMemberNotes`, {
            params: {
                members_ID: props.selectedMemberId
            }
        }).then(response => {
            let notesList = response.data;

            setState({
                ...state,
                notes: notesList,
                dataLoaded: true,
                showSingleNoteModal: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const columns = [
        {
            dataIndex: 'memberNotes_ID',
            title: 'memberNotes_ID',
            key: 'memberNotes_ID',
            hidden: true
        }, {
            dataIndex: 'dateCreated',
            title: 'Date',
            key: 'dateCreated',
            width: 120,
            render: (dateCreated) => {
                return (
                    <div>
                        {dateCreated === null ? '' : Moment(dateWithNoTimezone(dateCreated)).format("L")}
                    </div>
                );
            }
        }, {
            dataIndex: 'createdBy',
            title: 'Created By',
            key: 'createdBy',
            sort: true,
            width: 175
        }, {
            dataIndex: 'note',
            title: 'Note Details',
            key: 'note',
            sort: true
        }, {
            title: 'Edit Note',
            key: 'action',
            render: (record) => (
                <i id="editItem" className="fas fa-edit fa-1x ml-3 text-center hover" onClick={(e) => handleEditNote(e, record)}></i>
            ),
            width: 100,
        }
    ].filter(item => !item.hidden);

    function handleAddNewNote() {
        setState({
            ...state,
            selectedItemId: null,
            selectedIdx: null,
            noteEditMode: 'Add',
            selectedNote: null,
            showSingleNoteModal: true
        });
    }

    function handleEditNote(e, record) {
        console.log(record);

        setState({
            ...state,
            selectedItemId: record.memberNotes_ID,
            //selectedIdx: rowIndex,
            noteEditMode: 'Edit',
            selectedNote: record,
            showSingleNoteModal: true
        });
    }


    function DeleteCustomerNote(itemId) {
        //let postdata = {};

        //postdata.itemId = itemId;
        //Axios.post('/api/DeleteCustomerNote', postdata
        //).then(response => {
        //    let filterednotes = state.notes.filter(item => item.itemId !== itemId)

        //    setState({
        //        ...state,
        //        notes: filterednotes,
        //        dataLoaded: true,
        //        showNoteDetailsSlider: false
        //    });

        //    toast.success('Note deleted successfully');
        //}).catch(error => {
        //    console.log(error);
        //});
    }

    function updateTableDisplay(notes, idxToDirectTo = null) {
        setSelectedRowKeys([notes[idxToDirectTo].memberNotes_ID]);

        setState({
            ...state,
            notes: [...notes],
            selectedItemId: notes[idxToDirectTo].memberNotes_ID,
            showSingleNoteModal: false
        });
    }

    async function reloadNotesFromDB() {
        GetMemberNotes();

        setState({
            ...state,
            showSingleNoteModal: false
        });
    }

    const onSelectChange = (newSelectedRowKeys) => {
        //console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    

    return (
        <Fragment>
            <div className="form-group col-12 pb-3">
                <span className="color-dark-blue"><label className="form-label">Member Notes</label></span>
                <button className="btn btn-submit margin-left-15 float-right" onClick={handleAddNewNote}>Add Note</button>
            </div>

            <div>
                {state.dataLoaded &&
                    <Fragment>
                        {state.notes.length === 0 ?
                            <div style={{ fontSize: '18px', color: 'red' }}>No Notes Found</div>
                            :
                            <Fragment>
                                <div>
                                    <Table className="custom-ant-selection"
                                        rowKey={item => item.memberNotes_ID}
                                        rowSelection={rowSelection}
                                        bordered
                                        dataSource={state.notes}
                                        columns={columns}
                                        pagination={{
                                            defaultPageSize: 10,
                                            showSizeChanger: true,
                                            pageSizeOptions: ['10', '25', '50', '100'],
                                            showTotal: (total, range) => (
                                                <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                    Showing {range[0]}-{range[1]} of {total}
                                                </span>
                                            )
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: () => {
                                                    let selRows = [record.memberNotes_ID];
                                                    setSelectedRowKeys([...selRows]);
                                                },
                                                onDoubleClick: (e) => {
                                                    handleEditNote(e, record);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </Fragment>
                        }
                    </Fragment>
                }
            </div>

            {state.showSingleNoteModal &&
                <SingleNoteModal showModal={state.showSingleNoteModal} noteEditMode={state.noteEditMode} notes={state.notes} selectedNote={state.selectedNote} selectedIdx={state.selectedIdx} selectedItemId={state.selectedItemId}
                    updateTableDisplay={updateTableDisplay} reloadNotesFromDB={reloadNotesFromDB} selectedMemberId={props.selectedMemberId} />
            }
        </Fragment>
    );
}