import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from 'react-maskedinput';
import Moment from 'moment';
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";
import Globals from '../../config/globals';
import { Modal, message, Select } from 'antd';
import statesList from '../../helpers/StatesList';
import MemberSkillsSlider from './MemberSkillsSlider';
import ClassificationSlider from './ClassificationSlider';

export default function MemberInfoTab(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        showPane: props.showPane,
        selectedMemberId: '',
        selectedIdx: '',
        formFields: {},
        members: [],
        states: statesList,
        showMemberSkillsSlider: false,
        showClassificationSlider: false,
        showSkillsModal: false,
        classificationType: '',
        selectedMember: {}
    });

    const [modalInfo, setModalInfo] = useState({
        showmodal: false,
        content: {}
    });

    const dateFormat = 'MM/DD/YYYY';

    const workzoneOptions = ["1", "2", "3", "4"];

    useEffect(() => {
        setState({
            ...state,
            states: statesList
        });
    }, []);

    useEffect(() => {
        if (props.selectedMemberId !== null && props.selectedMemberId !== state.selectedMemberId) {
            Axios.get(`/api/GetMemberById`, {
                params: {
                    memberID: props.selectedMemberId
                }
            }).then(response => {
                console.log(response.data);

                var workZones = response.data.member.workZones;
                var workZonesArr = [];
                if (workZones !== null) {
                    workZonesArr = workZones.split(',');
                }

                setValue("formFields", response.data);
                setValue("editMode", "edit")
                setValue("formFields.memberWorkZones", workZonesArr)

                setState({
                    ...state
                });

            }).catch(error => {
                console.log(error);
            });
        }
        
    }, [props]);

    function hidePane() {
        //now update the parent list
        let members = props.members;

        //console.log(members);
        //console.log(getValues().formFields.members_ID);

        const selectedIdx = members.findIndex(m => m.member.members_ID === getValues().formFields.member.members_ID);
        members[selectedIdx] = getValues().formFields;

        //for graceful, animated close
        setTimeout(() => props.updateTableDisplay(members, selectedIdx), 1000);

        setState({
            ...state,
            showPane: false,
            showMemberSkillsSlider: false,
            showClassificationSlider: false,
            showSkillsModal: false,
        });
    }

    function onEditClassification(classificationType) {
        console.log(classificationType);

        setState({
            ...state,
            classificationType: classificationType,
            showMemberSkillsSlider: false,
            showSkillsModal: false,
            showClassificationSlider: true
        });
    }

    function onEditSkills() {
        setState({
            ...state,
            showMemberSkillsSlider: true,
            showClassificationSlider: false
        });
    }

    const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)

    function formatSSN(val) {
        if (val === undefined) {
            return '';
        }

        val = val.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{2})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        return val;
    }

    function onApplyChanges() {
        let formFields = state.formFields;
        formFields.contractorStatus = props.selectedContractorStatus;

        let myWorkZones = '';
        for (var i = 0; i < getValues().formFields.memberWorkZones.length; i++) {
            if (myWorkZones === '') {
                myWorkZones += getValues().formFields.memberWorkZones[i];
            }
            else {
                myWorkZones += ',' + getValues().formFields.memberWorkZones[i];
            }
        }
        setValue('formFields.member.workZones', myWorkZones);

        if (screenDataOK() === false) {
            return;
        }

        console.log(getValues().formFields.member);

        Axios.post(`/api/UpdateMember`, getValues().formFields.member
        ).then(response => {
            toast.success("Member Information Successfully Updated");

            //parent list gets updated when hidePane() is invoked;

        }).catch(error => {
            toast.error("Problem Encountered Updating Member");

            console.log(error);
        });
    }

    function onNotesClick() {
        //openModal();

        setState({
            ...state,
            showClassificationSlider: false,
            showSkillsModal: false,
            showMemberSkillsSlider: true
        });
    }

    function closeModal() {
        setModalInfo({
            ...modalInfo,
            showmodal: false
        });
    }

    function openModal() {
        setModalInfo({
            ...modalInfo,
            showmodal: true
        });
    }

    function updateClassification(classificationType, newValue) {
        switch (classificationType) {
            case 'Status':
                setValue('formFields.status', newValue.itemDescription);
                setValue('formFields.statusEffectiveDate', newValue.appliesFrom);

                break;
            case 'RateClass':
                setValue('formFields.rateClass', newValue.itemDescription);
                setValue('formFields.rateClassEffectiveDate', newValue.appliesFrom);

                break;
            case 'Trade':
                setValue('formFields.trade', newValue.itemDescription);
                setValue('formFields.tradeEffectiveDate', newValue.appliesFrom);

                break;
            case 'TradeLevel':
                setValue('formFields.tradeLevel', newValue.itemDescription);
                setValue('formFields.tradeLevelEffectiveDate', newValue.appliesFrom);

                break;
            default:
            // code block
        }

        setState({
            ...state,
            showClassificationSlider: false
        });
    }

    function updateSkills(skills) {
        let mySkillsText = '';

        skills.forEach(function (item, index) {
            console.log();

            if (Date.parse(item.skillExpirationDate) - Date.parse(new Date()) < 0) {
                // skill expired, skip it
                return;
            }

            if (mySkillsText === '') {
                mySkillsText = mySkillsText + item.skillCode + '-' + item.skillDescription;
            }
            else {
                mySkillsText = mySkillsText + '; ' + item.skillCode + '-' + item.skillDescription;
            }
        });

        setValue('formFields.skills', mySkillsText);

        setState({
            ...state,
            showMemberSkillsSlider: false
        });
    }

    function isValidDate(d) {
        //return d instanceof Date && !isNaN(d);

        return !isNaN(new Date(d));
    }

    function screenDataOK() {
        let message = '';
        let myHrsTot = 0;

        if (getValues().formFields.member.ssn === '') {
            message += '&bull;&nbsp;SSN #</br>';
        }
        if (getValues().formFields.member.cardClientNo === '') {
            message += '&bull;&nbsp;UA Card #</br>';
        }
        if (getValues().formFields?.member.lastName === '') {
            message += '&bull;&nbsp;Last Name</br>';
        }
        if (getValues().formFields?.member.firstName === '') {
            message += '&bull;&nbsp;First Name</br>';
        }
        if (getValues().formFields?.member.dateOfBirth === '' || getValues().formFields?.member.dateOfBirth === null || isValidDate(getValues().formFields?.member.dateOfBirth) === false) {
            message += '&bull;&nbsp;Date of Birth</br>';
        }
        if (getValues().formFields?.member.address1 === '') {
            message += '&bull;&nbsp;Address 1</br>';
        }
        if (getValues().formFields?.member.city === '') {
            message += '&bull;&nbsp;City</br>';
        }
        if (getValues().formFields?.member.state === '') {
            message += '&bull;&nbsp;State</br>';
        }
        if (getValues().formFields?.member.zip === '') {
            message += '&bull;&nbsp;Zip</br>';
        }
        if (getValues().formFields?.member.internationalInitiationDate === '' || getValues().formFields?.member.internationalInitiationDate === null || isValidDate(getValues().formFields?.member.internationalInitiationDate) === false) {
            message += '&bull;&nbsp;Initiation Date</br>';
        }
        if (getValues().formFields?.member.localInitiationDate === '' || getValues().formFields?.member.localInitiationDate === null || isValidDate(getValues().formFields?.member.localInitiationDate) === false) {
            message += '&bull;&nbsp;Joined Local</br>';
        }
        if (getValues().formFields?.member.cellPhone === '') {
            message += '&bull;&nbsp;Cell Phone</br>';
        }

        if (message != '') {
            message = "<strong>Mandatory fields missing or invalid data specified</strong><br />" + message;
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return false;
        }
        else {
            return true;
        }
    }

    function handleWorkzoneSelectorFieldChange(value) {
        setValue('formFields.memberWorkZones', value);
        setState({ ...state });
    }

    return (
        <Fragment>
            <Card className="local777Card">
                <Card.Header><span>Member Information&nbsp;&nbsp;&nbsp;* Indicates Required Fields</span></Card.Header>
                <Card.Body>
                    <div className="row">
                        <div className="form-group col-lg-1">
                            <label className="form-label"><span style={{ color: 'red' }} >* </span>SSN #</label>
                            <input type="text" className="form-control-custom" {...register('formFields.member.ssn')} />
                        </div>
                        <div className="form-group col-lg-1">
                            <label className="form-label"><span style={{ color: 'red' }} >* </span>Card #</label>
                            <input type="text" className="form-control-custom" {...register('formFields.member.cardClientNo')} />
                        </div>
                        <div className="form-group col-lg-2">
                            <label className="form-label"><span style={{ color: 'red' }} >* </span>Last Name</label>
                            <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.lastName')} />
                        </div>
                        <div className="form-group col-lg-2">
                            <label className="form-label"><span style={{ color: 'red' }} >* </span>First Name</label>
                            <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.firstName')} />
                        </div>
                        <div className="form-group col-lg-1">
                            <label className="form-label">Middle Initial</label>
                            <input type="text" maxLength="1" className="form-control-custom text-uppercase" {...register('formFields.member.middleInitial')} />
                        </div>
                        <div className="form-group col-lg-1">
                            <label className="form-label">Suffix</label>
                            <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.suffix')} />
                        </div>
                        <div className="form-group col-lg-1">
                            <label className="form-label"><span style={{ color: 'red' }} >* </span>Date of Birth</label>
                            <Controller
                                name="formFields.member.dateOfBirth"
                                control={control}
                                setValue={setValue}
                                defaultValue={getValues().formFields?.member.dateOfBirth ? Moment(dateWithNoTimezone(getValues().formFields.member.dateOfBirth)).toDate() : null}
                                render={() =>
                                    <DatePicker
                                        selected={getValues().formFields?.member.dateOfBirth ? Moment(dateWithNoTimezone(getValues().formFields.member.dateOfBirth)).toDate() : null}
                                        onChange={date => setValue('formFields.member.dateOfBirth', date)}
                                        className="form-control-custom"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        dateFormat="MM/dd/yyyy"
                                        customInput={
                                            <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" />
                                        }
                                        preventOpenOnFocus={true}
                                    />
                                }
                            />
                        </div>
                        <div className="form-group col-lg-1">
                            <label className="form-label">Age</label><br />
                            <label className="form-label"><b>{getValues().formFields?.member.dateOfBirth ? getAge(getValues().formFields.member.dateOfBirth) : ''}</b></label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-2">
                            <label className="form-label"><span style={{ color: 'red' }} >* </span>Address 1</label>
                            <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.address1')} />
                        </div>
                        <div className="form-group col-lg-2">
                            <label className="form-label">Address 2</label>
                            <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.address2')} />
                        </div>
                        <div className="form-group col-lg-2">
                            <label className="form-label"><span style={{ color: 'red' }} >* </span>City</label>
                            <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.city')} />
                        </div>
                        <div className="form-group col-lg-1">
                            <label className="form-label"><span style={{ color: 'red' }} >* </span>State</label>
                            <select className="form-control-custom text-uppercase" {...register('formFields.member.state')}>
                                <option></option>
                                {state.states.map((state, idx) => <option key={idx} value={state.abbreviation}>{state.abbreviation}</option>)}
                            </select>
                        </div>
                        <div className="form-group col-lg-1">
                            <label className="form-label"><span style={{ color: 'red' }} >* </span>Zip</label>
                            <input type="text" className="form-control-custom text-uppercase" {...register('formFields.member.zip')} />
                        </div>
                        <div className="form-group col-lg-1">
                            <label className="form-label">Country</label>
                            <select className="form-control-custom" {...register('formFields.member.country')}>
                                <option></option>
                                <option>USA</option>
                                <option>Unknown</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-1">
                            <label className="form-label"><span style={{ color: 'red' }} >* </span>Init. Date</label>
                            <Controller
                                name="formFields.member.internationalInitiationDate"
                                control={control}
                                setValue={setValue}
                                defaultValue={getValues().formFields?.member.internationalInitiationDate ? Moment(dateWithNoTimezone(getValues().formFields.member.internationalInitiationDate)).toDate() : null}
                                render={() =>
                                    <DatePicker
                                        selected={getValues().formFields?.member.internationalInitiationDate ? Moment(dateWithNoTimezone(getValues().formFields.member.internationalInitiationDate)).toDate() : null}
                                        onChange={date => setValue('formFields.member.internationalInitiationDate', date)}
                                        className="form-control-custom"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        dateFormat="MM/dd/yyyy"
                                        customInput={
                                            <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" />
                                        }
                                        preventOpenOnFocus={true}
                                    />
                                }
                            />
                        </div>
                        <div className="form-group col-lg-1">
                            <label className="form-label"><span style={{ color: 'red' }} >* </span>Joined Local</label>
                            <Controller
                                name="formFields.member.localInitiationDate"
                                control={control}
                                setValue={setValue}
                                defaultValue={getValues().formFields?.member.localInitiationDate ? Moment(dateWithNoTimezone(getValues().formFields.member.localInitiationDate)).toDate() : null}
                                render={() =>
                                    <DatePicker
                                        selected={getValues().formFields?.member.localInitiationDate ? Moment(dateWithNoTimezone(getValues().formFields.member.localInitiationDate)).toDate() : null}
                                        onChange={date => setValue('formFields.member.localInitiationDate', date)}
                                        className="form-control-custom"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        dateFormat="MM/dd/yyyy"
                                        customInput={
                                            <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" />
                                        }
                                        preventOpenOnFocus={true}
                                    />
                                }
                            />
                        </div>
                        <div className="form-group col-lg-1">
                            <label className="form-label">Current Local</label>
                            <input type="text" className="form-control-custom" {...register('formFields.member.currentLocal')} />
                        </div>
                        <div className="form-group col-lg-1">
                            <label className="form-label">Prior Local</label>
                            <input type="text" className="form-control-custom" {...register('formFields.member.priorLocal')} />
                        </div>
                        {/*<div className="form-group col-lg-1">*/}
                        {/*    <label className="form-label">2nd Prior Local</label>*/}
                        {/*    <input type="text" className="form-control-custom" {...register('formFields.member.secondPriorLocal')} />*/}
                        {/*</div>*/}
                        <div className="form-group col-lg-1">
                            <label className="form-label">EB Badge No.</label>
                            <input type="text" className="form-control-custom" {...register('formFields.member.ebBadgeNo')} />
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <div className="row">
                <div className="col col-lg-5 col-12">
                    <Card className="local777Card" style={{ minHeight: '325px' }}>
                        <Card.Header><span>Classifications</span></Card.Header>
                        <Card.Body>
                            <div className="row">
                                {getValues().editMode === 'add' ?
                                    <div class="text-center" style={{ color: 'red' }}>
                                        You must click 'Apply Changes' to save new member before accessing this information
                                    </div>
                                    :
                                    <Fragment>
                                        {/*<div className="col-lg-4">*/}
                                        {/*    <label className="form-label mb-0">Local Status:</label>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-lg-8">*/}
                                        {/*    <span><b>{getValues().formFields?.localStatus}</b>&nbsp;&nbsp;&nbsp;<i id="editLocalStatus" onClick={(e) => onEditClassification('LocalStatus')} className="fas fa-edit fa-1x pl-5 text-center hover"></i></span>*/}
                                        {/*</div>*/}

                                        <div className="col-lg-4">
                                            <label className="form-label mb-0"><span style={{ color: 'red' }} >* </span>Status:</label>
                                        </div>
                                        <div className="col-lg-8">
                                            {getValues().formFields?.status !== null ?
                                                <div>
                                                    <span><b>{getValues().formFields?.status}</b> [Effective Date: {Moment(dateWithNoTimezone(getValues().formFields?.statusEffectiveDate)).format("L")}]&nbsp;&nbsp;&nbsp;<i id="editStatus" onClick={(e) => onEditClassification('Status')} className="fas fa-edit fa-1x pl-5 text-center hover"></i></span>
                                                </div>
                                                :
                                                <div>
                                                    No Status History Found&nbsp;&nbsp;&nbsp;<i id="editStatus" onClick={(e) => onEditClassification('Status')} className="fas fa-edit fa-1x pl-5 text-center hover"></i>
                                                </div>
                                            }

                                        </div>

                                        {/*<div className="col-lg-4">*/}
                                        {/*    <label className="form-label mb-0">Class:</label>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-lg-8">*/}
                                        {/*    <span><b>{getValues().formFields?.class}</b> [Effective Date: {Moment(dateWithNoTimezone(getValues().formFields?.classEffectiveDate)).format("L")}]&nbsp;&nbsp;&nbsp;<i id="editClass" onClick={(e) => onEditClassification('Class')} className="fas fa-edit fa-1x pl-5 text-center hover"></i></span>*/}
                                        {/*</div>*/}

                                        <div className="col-lg-4">
                                            <label className="form-label mb-0">&nbsp;&nbsp;Paid Thru Date:</label>
                                        </div>
                                        <div className="col-lg-8">
                                            {getValues().formFields?.member.paidthruDate !== null ?
                                                <div>
                                                    <span><b>{Moment(dateWithNoTimezone(getValues().formFields?.member.paidthruDate)).format("L")}</b></span>
                                                </div>
                                                :
                                                <div>
                                                    No Payment History Found
                                                </div>
                                            }

                                        </div>

                                        <div className="col-lg-4">
                                            <label className="form-label mb-0"><span style={{ color: 'red' }} >* </span>Rate Class:</label>
                                        </div>
                                        <div className="col-lg-8">
                                            {getValues().formFields?.rateClass !== null ?
                                                <div>
                                                    <span><b>{getValues().formFields?.rateClass}</b> [Effective Date: {Moment(dateWithNoTimezone(getValues().formFields?.rateClassEffectiveDate)).format("L")}]&nbsp;&nbsp;&nbsp;<i id="editRateClass" onClick={(e) => onEditClassification('RateClass')} className="fas fa-edit fa-1x pl-5 text-center hover"></i></span>
                                                </div>
                                                :
                                                <div>
                                                    No Rate Class History Found&nbsp;&nbsp;&nbsp;<i id="editRateClass" onClick={(e) => onEditClassification('RateClass')} className="fas fa-edit fa-1x pl-5 text-center hover"></i>
                                                </div>
                                            }
                                        </div>

                                        {/*<div className="col-lg-4">*/}
                                        {/*    <label className="form-label mb-0">Apprentice Class Level:</label>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-lg-8">*/}
                                        {/*    <span><b>{getValues().formFields?.apprenticeClass}</b>&nbsp;&nbsp;&nbsp;<i id="editApprenticeClass" onClick={(e) => onEditClassification('ApprenticeClass')} className="fas fa-edit fa-1x pl-5 text-center hover"></i></span>*/}
                                        {/*</div>*/}

                                        <div className="col-lg-4">
                                            <label className="form-label mb-0">&nbsp;&nbsp;Current Employer:</label>
                                        </div>
                                        <div className="col-lg-8">
                                            <span><b></b></span>
                                        </div>

                                        <div className="col-lg-4">
                                            <label className="form-label mb-0"><span style={{ color: 'red' }} >* </span>Trade:</label>
                                        </div>
                                        <div className="col-lg-8">
                                            {getValues().formFields?.trade !== null ?
                                                <div>
                                                    <span><b>{getValues().formFields?.trade}</b> [Effective Date: {Moment(dateWithNoTimezone(getValues().formFields?.tradeEffectiveDate)).format("L")}]&nbsp;&nbsp;&nbsp;<i id="editTrade" onClick={(e) => onEditClassification('Trade')} className="fas fa-edit fa-1x pl-5 text-center hover"></i></span>
                                                </div>
                                                :
                                                <div>
                                                    No Trade History Found&nbsp;&nbsp;&nbsp;<i id="editTrade" onClick={(e) => onEditClassification('Trade')} className="fas fa-edit fa-1x pl-5 text-center hover"></i>
                                                </div>
                                            }
                                        </div>

                                        <div className="col-lg-4">
                                            <label className="form-label mb-0"><span style={{ color: 'red' }} >* </span>Trade Level:</label>
                                        </div>
                                        <div className="col-lg-8">
                                            {getValues().formFields?.tradeLevel !== null ?
                                                <div>
                                                    <span><b>{getValues().formFields?.tradeLevel}</b> [Effective Date: {Moment(dateWithNoTimezone(getValues().formFields?.tradeLevelEffectiveDate)).format("L")}]&nbsp;&nbsp;&nbsp;<i id="editTradeLevel" onClick={(e) => onEditClassification('TradeLevel')} className="fas fa-edit fa-1x pl-5 text-center hover"></i></span>
                                                </div>
                                                :
                                                <div>
                                                    No Trade Level History Found&nbsp;&nbsp;&nbsp;&nbsp;<i id="editTradeLevel" onClick={(e) => onEditClassification('TradeLevel')} className="fas fa-edit fa-1x pl-5 text-center hover"></i>
                                                </div>
                                            }

                                        </div>

                                        <div className="col-lg-4">
                                            <label className="form-label mb-0">&nbsp;&nbsp;Work Zones:</label>
                                        </div>
                                        <div className="col-lg-8">
                                            <Select id="ddWorkZones"
                                                value={getValues().formFields?.memberWorkZones}
                                                className="form-control-custom"
                                                mode="multiple"
                                                placeholder="Select one or more Workzones"
                                                onChange={(value) => handleWorkzoneSelectorFieldChange(value)}
                                                className="form-control-custom w-100"
                                                bordered={true}
                                            >
                                                <Option key="1" value="1">1</Option>
                                                <Option key="2" value="2">2</Option>
                                                <Option key="3" value="3">3</Option>
                                                <Option key="4" value="4">4</Option>
                                            </Select>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col col-lg-4 col-12">
                    <Card className="local777Card">
                        <Card.Header><span>Contact Information</span></Card.Header>
                        <Card.Body>
                            <div className="row">
                                <div className="form-group col-lg-4"><label className="form-label">Email</label></div>
                                <div className="form-group col-lg-8"><input type="text" className="inline-text-box" {...register('formFields.member.emailAddress')} /></div>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-4"><label className="form-label"><span style={{ color: 'red' }} >* </span>Cell Phone</label></div>
                                <div className="form-group col-lg-4"><input type="text" className="inline-text-box" {...register('formFields.member.cellPhone')} /></div>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-4"><label className="form-label">Emergency Phone</label></div>
                                <div className="form-group col-lg-4"><input type="text" className="inline-text-box" {...register('formFields.member.emergencyPhone')} /></div>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-4"><label className="form-label">Emergency Contact</label></div>
                                <div className="form-group col-lg-6"><input type="text" className="inline-text-box" {...register('formFields.member.emergencyContact')} /></div>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-4"><label className="form-label">Work Phone</label></div>
                                <div className="form-group col-lg-4"><input type="text" className="inline-text-box" {...register('formFields.member.workPhone')} /></div>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-4"><label className="form-label">Evening Phone</label></div>
                                <div className="form-group col-lg-4"><input type="text" className="inline-text-box" {...register('formFields.member.eveningPhone')} /></div>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-4"><label className="form-label">Alt Phone</label></div>
                                <div className="form-group col-lg-4"><input type="text" className="inline-text-box" {...register('formFields.member.alternative')} /></div>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-4"><label className="form-label">Beneficiary</label></div>
                                <div className="form-group col-lg-8"><input type="text" className="inline-text-box" {...register('formFields.member.beneficiary')} /></div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col col-lg-3 col-12">
                    <Card className="local777Card">
                        <Card.Header><span>Census Information</span></Card.Header>
                        <Card.Body>
                            <div className="row">
                                <div className="form-group col-lg-4"><label className="form-label">Gender</label></div>
                                <div className="form-group col-lg-4">
                                    <select className="form-control-custom" {...register('formFields.member.gender')}>
                                        <option></option>
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-4"><label className="form-label">Marital Status</label></div>
                                <div className="form-group col-lg-4">
                                    <select className="form-control-custom" {...register('formFields.member.maritalStatus')}>
                                        <option></option>
                                        <option>Divorced</option>
                                        <option>Married</option>
                                        <option>Single</option>
                                        <option>Unknown</option>
                                        <option>Widowed</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-4"><label className="form-label">Race</label></div>
                                <div className="form-group col-lg-4">
                                    <select className="form-control-custom" {...register('formFields.member.race')}>
                                        <option></option>
                                        <option>African American</option>
                                        <option>Alaska Native</option>
                                        <option>Asian</option>
                                        <option>Black</option>
                                        <option>Caucasian</option>
                                        <option>FE</option>
                                        <option>Hawaiian/Pac Isl</option>
                                        <option>Hispanic</option>
                                        <option>Mid East Indian</option>
                                        <option>Native American</option>
                                        <option>Other</option>
                                        <option>Unknown</option>
                                        <option>White</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-4"><label className="form-label">Secondary Race</label></div>
                                <div className="form-group col-lg-4">
                                    <select className="form-control-custom" {...register('formFields.member.secondaryRace')}>
                                        <option></option>
                                        <option>African American</option>
                                        <option>Alaska Native</option>
                                        <option>Asian</option>
                                        <option>Black</option>
                                        <option>Caucasian</option>
                                        <option>FE</option>
                                        <option>Hawaiian/Pac Isl</option>
                                        <option>Hispanic</option>
                                        <option>Mid East Indian</option>
                                        <option>Native American</option>
                                        <option>Other</option>
                                        <option>Unknown</option>
                                        <option>White</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-4"><label className="form-label">Veteran</label></div>
                                <div className="form-group col-lg-4">
                                    <select className="form-control-custom" {...register('formFields.member.veteran')}>
                                        <option></option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </select>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <Card className="local777Card">
                <Card.Header><span>Member Skills</span></Card.Header>
                <Card.Body>
                    {getValues().editMode === 'add' ?
                        <div class="text-left" style={{ color: 'red' }}>
                            You must click 'Apply Changes' to save new member before accessing this information
                        </div>
                        :
                        <div>
                            <label className="form-label">Skills:&nbsp;</label><span><b>{getValues().formFields?.skills}</b> &nbsp;&nbsp;&nbsp;<i id="editSkills" onClick={(e) => onEditSkills()} className="fas fa-edit fa-1x pl-5 text-center hover"></i></span>
                        </div>
                    }
                </Card.Body>
            </Card>
            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <div class="text-left">
                        <span><button className="btn btn-submit" onClick={onApplyChanges}>Apply Changes</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>
                </div>
            </div>

            {state.showClassificationSlider &&
                <ClassificationSlider showPane={state.showClassificationSlider} classificationType={state.classificationType} memberID={getValues().formFields.member.members_ID} updateClassification={updateClassification} />
            }

            {state.showMemberSkillsSlider &&
                <MemberSkillsSlider showPane={state.showMemberSkillsSlider} memberID={getValues().formFields.member.members_ID} updateSkills={updateSkills} />
            }
        </Fragment>
    );
}