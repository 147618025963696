import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";
import Globals from '../../config/globals';
import { Modal, message, Spin, Select, Alert, Table } from 'antd';

export default function SingleSkillModal(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        showModal: props.showModal,
        modalTitle: '',
        buttonLabel: '',
        availableSkillsList: [],
        assignedSkillsList: [],
        memberID: props.memberID,
        skillEditMode: props.skillEditMode,
        dataLoaded: false,
        skillsListLoaded: false,
        selectedSkill: {}
    });


    const dateFormat = 'MM/DD/YYYY';

    useEffect(() => {
        setState({
            ...state,
            skillsListLoaded: false
        });

        console.log(props.selectedSkill);

        GetAvailableSkills();
    }, [props]);

    useEffect(() => {
        var myModalTitle;
        var myButtonLabel;
        var mySelectedSkill;

        if (state.skillsListLoaded === true) {
            if (props.skillEditMode === 'Edit') {
                myModalTitle = 'Update Member Skill';
                myButtonLabel = 'Update Skill';
                mySelectedSkill = props.selectedSkill;
                setValue('formFields.selectedListItem', mySelectedSkill.skillsMaster_ID);
                setValue('formFields.dateSkillAwarded', mySelectedSkill.dateSkillAwarded);
                setValue('formFields.skillExpirationDate', mySelectedSkill.skillExpirationDate);
            }
            else {
                myModalTitle = 'Add Member Skill';
                myButtonLabel = 'Add Skill';
                mySelectedSkill = null;
                setValue('formFields.selectedListItem', '');
                setValue('formFields.dateSkillAwarded', null);
                setValue('formFields.skillExpirationDate', null);
            }

            setState({
                ...state,
                showModal: props.showModal,
                modalTitle: myModalTitle,
                buttonLabel: myButtonLabel,
                selectedSkill: mySelectedSkill,
                dataLoaded: true
            });
        }

    }, [state.skillsListLoaded]);

    function GetAvailableSkills() {
        Axios.get(`/api/GetAvailableSkills`, {
            params: {
                memberID: props.memberID
            }
        }).then(response => {
            setState({
                ...state,
                availableSkillsList: response.data,
                skillsListLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    function UpdateSkill() {
        var postdata = {};

        if (props.skillEditMode === 'Edit') {
            postdata = {
                Members_ID: props.memberID,
                MemberSkills_ID: props.selectedSkill.memberSkills_ID,
                SkillsMaster_ID: getValues().formFields.selectedListItem,
                DateSkillAwarded: formatDate(getValues().formFields?.dateSkillAwarded),
                SkillExpirationDate: formatDate(getValues().formFields?.skillExpirationDate),
                CreatedBy: Globals.userInfo.kmrUserID
            }

            Axios.post(`/api/UpdateMemberSkill`, postdata
            ).then(response => {
                let updatedSkillsList = response.data;

                toast.success('Member skill successfully updated');

                //now update the parent list
                const selectedIdx = updatedSkillsList.findIndex(s => s.memberSkills_ID === props.selectedSkill.memberSkills_ID);

                //for graceful, animated close
                setTimeout(() => props.updateTableDisplay(updatedSkillsList, selectedIdx), 1000);

                closeModal();

            }).catch(error => {
                toast.error('There was a problem updating Member Skill');
            });
        }
        else {
            postdata = {
                Members_ID: props.memberID,
                SkillsMaster_ID: getValues().formFields.selectedListItem,
                DateSkillAwarded: formatDate(getValues().formFields?.dateSkillAwarded),
                SkillExpirationDate: formatDate(getValues().formFields?.skillExpirationDate),
                CreatedBy: Globals.userInfo.kmrUserID
            }

            Axios.post(`/api/InsertMemberSkill`, postdata
            ).then(response => {
                let updatedSkillsList = response.data.skills;
                let newID = response.data.newID;

                console.log(newID);
                console.log(updatedSkillsList);

                toast.success('Member Skill successfully added');

                //now update the parent list
                const selectedIdx = updatedSkillsList.findIndex(s => s.memberSkills_ID === newID);

                //for graceful, animated close
                setTimeout(() => props.updateTableDisplay(updatedSkillsList, selectedIdx), 1000);

                closeModal();

            }).catch(error => {
                toast.error('There was a problem adding Member Skill');
            });
        }
    }

    function closeModal() {
        //This prevents the modal from opening in the parent unless called upon again
        props.disableSingleSkillModal();

        setState({
            ...state,
            showModal: false
        });
    }

    function openModal() {
        setState({
            ...state,
            showModal: true
        });
    }

    function onCompleteRequest() {
        console.log(getValues().formFields.selectedListItem);
        console.log(getValues().formFields.dateSkillAwarded);
        console.log(getValues().formFields.skillExpirationDate);

        if (getValues().formFields.selectedListItem === '' || getValues().formFields.dateSkillAwarded === '' || getValues().formFields.dateSkillAwarded === null || getValues().formFields.skillExpirationDate === '' || getValues().formFields.skillExpirationDate === null) {
            toast.error('All fields are required');

            return;
        }

        UpdateSkill();
    }

    return (
        <>
            <Modal
                visible={state.showModal}
                title={state.modalTitle}
                onCancel={closeModal}
                footer={[
                    <button className="btn btn-submit" onClick={onCompleteRequest}>
                        {state.buttonLabel}
                    </button>,
                    <span>&nbsp;&nbsp;</span>,
                    <button className="btn btn-outline-primary" onClick={closeModal}>
                        Close
                    </button>
                ]}
            >
                <div className="row">
                    <div className="form-group col-lg-5">
                        <label className="form-label mb-0">Select New Skill:</label>
                    </div>
                    <div className="form-group col-lg-7">
                        <select className="form-control-custom" {...register('formFields.selectedListItem')}>
                            <option></option>
                            {state.availableSkillsList?.map((li, idx) => <option key={idx} value={li.kmrListItemID}>{li.listItemName}</option>)}
                        </select>
                    </div>

                    <div className="form-group col-lg-5">
                        <label className="form-label mb-0">Effective Date:</label>
                    </div>
                    <div className="form-group col-lg-4">
                        <Controller
                            name="formFields.dateSkillAwarded"
                            control={control}
                            setValue={setValue}
                            defaultValue={getValues().formFields?.dateSkillAwarded ? Moment(getValues().formFields.dateSkillAwarded).toDate() : null}
                            render={() =>
                                <DatePicker
                                    selected={getValues().formFields?.dateSkillAwarded ? Moment(getValues().formFields.dateSkillAwarded).toDate() : null}
                                    onChange={date => setValue('formFields.dateSkillAwarded', date)}
                                    className="form-control-custom"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="MM/dd/yyyy"
                                />
                            }
                        />
                    </div>
                    <div className="form-group col-lg-3"></div>

                    <div className="form-group col-lg-5">
                        <label className="form-label mb-0">Expiration Date:</label>
                    </div>
                    <div className="form-group col-lg-4">
                        <Controller
                            name="formFields.skillExpirationDate"
                            control={control}
                            setValue={setValue}
                            defaultValue={getValues().formFields?.skillExpirationDate ? Moment(getValues().formFields.skillExpirationDate).toDate() : null}
                            render={() =>
                                <DatePicker
                                    selected={getValues().formFields?.skillExpirationDate ? Moment(getValues().formFields.skillExpirationDate).toDate() : null}
                                    onChange={date => setValue('formFields.skillExpirationDate', date)}
                                    className="form-control-custom"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="MM/dd/yyyy"
                                />
                            }
                        />
                    </div>
                    <div className="form-group col-lg-3"></div>
                </div>
            </Modal>
        </>
    );
}