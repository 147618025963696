import { BehaviorSubject } from 'rxjs';
import Axios from '../config/axios';
import axios from 'axios';
import Globals from '../config/globals';
import { imitationMode, userInfo, userPermissions } from './UsersService';

export function insertAuditLogin(postdata) {
    return Axios.post(`/api/InsertAuditLogin`, postdata)
        .then(response => response.data)
        .catch(error => error);
}

export async function getIPv4() {
    const res = await axios.get('https://geolocation-db.com/json/')
    return res?.data?.IPv4;
}

export function logoutAudit() {
    let auditLogin_ID = window.sessionStorage.getItem("uid");
    if (auditLogin_ID !== null) {
        let postdata = {
            auditLogin_ID
        };

        sessionStorage.clear();

        Globals.isDirtyPage = false;
        userInfo.next({});
        userPermissions.next({});
        imitationMode.next(false);
        return Axios.post('/api/LogoutAuditLogin', postdata)
            .then(response => {

                Globals.bearer = null;
            })
            .catch(error => error);
    }
}