import Axios from '../config/axios';

export function validateUser(userid, password) {
    let postdata = {
        userid,
        password
    }

    return Axios.post('/api/ValidateUser', postdata).then(response => response.data);
}

export function validateUserByUid(auditLogin_ID) {
    let postdata = {
        auditLogin_ID
    }

    return Axios.post('/api/ValidateUserByUid', postdata).then(response => response.data);
}