import React, { useEffect, useState } from 'react';
import { Layout as AntLayout, Alert } from 'antd';

import {
    MenuFoldOutlined,
    MenuUnfoldOutlined
} from '@ant-design/icons';

import Globals from '../config/globals';

import MediaQuery, { useMediaQuery } from 'react-responsive';
import { Sider } from './Nav/Sider';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import logo from '../img/logo_saved.png';

import { useNavigate } from "react-router-dom";
import { TopNav } from './Nav/TopNav';

const { Content, Footer, Header } = AntLayout;

export function Layout(props) {
    const navigate = useNavigate();

    const [collapsed, setCollapsed] = useState(true);

    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    //let imitationMode = window.sessionStorage.getItem("imitationMode");

    useEffect(() => {
        if (isDesktop) {
            setCollapsed(true);
        }
    }, [isDesktop]);

    return (
        <AntLayout className="layout h-100">

            <TopNav />

            {/*<MediaQuery minWidth={Globals.mobileWidth + 1}>*/}
            {/*    <TopNav />*/}
            {/*</MediaQuery>*/}

            {/*<MediaQuery maxWidth={Globals.mobileWidth}>*/}
            {/*    <Header className="d-flex mobile-header ant-menu-mobile">*/}
            {/*        <div></div>*/}
            {/*        <div>*/}
            {/*            <img src={logo} style={{ height: '50px' }} />*/}
            {/*        </div>*/}
            {/*        <div style={{ margin: '0 18px' }}>*/}
            {/*            {collapsed ? <MenuFoldOutlined style={{ fontSize: '2rem' }} onClick={() => setCollapsed(!collapsed)} /> : <MenuUnfoldOutlined style={{ fontSize: '2rem' }} onClick={() => setCollapsed(!collapsed)} />}*/}
            {/*        </div>*/}
            {/*    </Header>*/}
            {/*</MediaQuery>*/}

            <AntLayout>
                {/*<Sider collapsed={collapsed} closeSider={() => setCollapsed(true)} />*/}
                {collapsed &&
                    <Content className="h-100" style={{ padding: '20px 50px' }}>
                        <div className="site-layout-content">{props.children}</div>
                    </Content>
                }
            </AntLayout>

            <Footer style={{ textAlign: 'center' }}>Local777 Member Manager</Footer>
        </AntLayout>
    );
}