import React, { useEffect, useState } from 'react';
import Axios from '../../config/axios';
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs } from 'antd';
import MemberInfoTab from './MemberInfoTab';
import MemberNotesTab from './MemberNotesTab';
import MemberDateEventsTab from './MemberDateEventsTab';
import MemberPaymentsTab from './MemberPaymentsTab';
import Moment from 'moment';
import { dateWithNoTimezone } from '../../helpers/DateFormat';

export default function MemberDashboard(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        selectedMember: {},
        selectedMemberId: location?.state?.selectedMemberId || '',
        selectedMemberName: location?.state?.selectedMemberName || '',
        formFields: {},
        members: location?.state?.members || [],
        filteredMembers: location?.state?.filteredMembers || [],
        filteredMatchedMembers: location?.state?.filteredMatchedMembers || [],
        selectedMemberStatus: location?.state?.selectedMemberStatus || '',
        searchValue: location?.state?.searchValue || ''
    });

    const { TabPane } = Tabs;

    useEffect(() => {
        setState({
            ...state,
            selectedMember: {}
        });

        Axios.get(`/api/GetMemberById`, {
            params: {
                memberID: location.state.selectedMemberId
            }
        }).then(response => {
            console.log(response.data);

            setState({
                ...state,
                selectedMember: response.data,
                selectedMemberId: location.state.selectedMemberId,
                selectedMemberName: location.state.selectedMemberName,
                selectedMemberStatus: response.data.member.status
            });

        }).catch(error => {
            console.log(error);
        });
    }, [props]);

    function backToSearchResults() {
        console.log(state.selectedMember);

        navigate("/members", {
            replace: true, state: {
                selectedMemberId: state.selectedMemberId, selectedMember: state.selectedMember, selectedMemberStatus: state.selectedMemberStatus, 
                members: state.members, filteredMembers: state.filteredMembers, filteredMatchedMembers: state.filteredMatchedMembers,
                selectedMemberStatus: state.selectedMemberStatus, searchValue: state.searchValue,
                currentPage: location.state.currentPage, currentPageSize: location.state.currentPageSize
            }
        });
    }

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    /*console.log(state.selectedMember.member?.paidthruDate);*/
    console.log(state.selectedMember);

    return (
        <div>
            {location.state.accounts !== undefined ?
                <span className="color-dark-blue hover" onClick={backToSearchResults}> <i className="fas fa-arrow-left" onClick={backToSearchResults}></i> Back to search results</span>
                :
                <span className="color-dark-blue hover" onClick={backToSearchResults}> <i className="fas fa-arrow-left" onClick={backToSearchResults}></i> Back to all members</span>
            }

            <div>
                <div className="row padding-top-15 ml-3 mr-3 justify-content-between">
                    <div className="col">
                        <h3 className="">{state.selectedMemberName}</h3>
                    </div>

                    <div className="col text-right">
                        <div style={{ border: '3px solid #fff', padding: 20 }} >
                            <div style={{ width: '20%', float: 'left', padding: 20, border: 'none', fontWeight: 'bold', fontSize: '1.2em' }}>
                                <div>Account Summary:</div>
                            </div>

                            <div style={{ width: '80%', float: 'right', padding: 20, border: 'solid thin navy' }}>
                                <table style={{ border: 'none', padding: '10px' }}>
                                    <tbody>
                                        {/*<tr>*/}
                                        {/*    <td style={{ paddingLeft: '0px', fontWeight: 'bold' }}>Paid through: {Moment(state.selectedMember.member?.paidthruDate).isAfter() ? <span dangerouslySetInnerHTML={{ __html: '<span style="color:green">' + Moment(dateWithNoTimezone(state.selectedMember.member?.paidthruDate)).format("L") + '</span>' }}></span> : <span dangerouslySetInnerHTML={{ __html: '<span style="color:red">' + Moment(dateWithNoTimezone(state.selectedMember.member?.paidthruDate)).format("L") + '</span>' }}></span>}</td>*/}
                                        {/*    <td style={{ paddingLeft: '50px', fontWeight: 'bold' }}>Dues Owed: {state.selectedMember?.monthsToGetCurrent > 0 ? <span dangerouslySetInnerHTML={{ __html: '<span style="color:red">' + currencyFormatter.format(state.selectedMember?.monthsToGetCurrent * state.selectedMember?.duesRate) + '</span>' }}></span> : '$0.00'}</td>*/}
                                        {/*    <td style={{ paddingLeft: '50px', fontWeight: 'bold' }}>Reinstatement Owed: {state.selectedMember?.monthsToGetCurrent > 3 ? <span dangerouslySetInnerHTML={{ __html: '<span style="color:red">' + currencyFormatter.format(state.selectedMember?.reinstatementRate) + '</span>' }}></span> : '$0.00'}</td>*/}
                                        {/*    <td style={{ paddingLeft: '50px', fontWeight: 'bold' }}>Miscellaneous Credit: {state.selectedMember?.miscellaneousCredit > 0 ? <span dangerouslySetInnerHTML={{ __html: '<span style="color:green">' + currencyFormatter.format(state.selectedMember?.miscellaneousCredit) + '</span>' }}></span> : '$0.00'}</td>*/}
                                        {/*</tr>*/}
                                        <tr>
                                            <td style={{ paddingLeft: '0px', fontWeight: 'bold' }}>Paid through: {Moment(state.selectedMember.member?.paidthruDate).isAfter() ? <span style={{ color: 'green' }} >{Moment(dateWithNoTimezone(state.selectedMember.member?.paidthruDate)).format("L")}</span> : <span style={{color: 'red'}}>{Moment(dateWithNoTimezone(state.selectedMember.member?.paidthruDate)).format("L")}</span>}</td>
                                            <td style={{ paddingLeft: '50px', fontWeight: 'bold' }}>Dues Owed: {state.selectedMember?.monthsToGetCurrent > 0 ? <span style={{ color: 'red' }}>{currencyFormatter.format(state.selectedMember?.monthsToGetCurrent * state.selectedMember?.duesRate)}</span> : <span>$0.00</span>}</td>
                                            <td style={{ paddingLeft: '50px', fontWeight: 'bold' }}>Reinstatement Owed: {state.selectedMember?.monthsToGetCurrent > 3 ? <span style={{ color: 'red' }}>{currencyFormatter.format(state.selectedMember?.reinstatementRate)}</span> : <span>$0.00</span>}</td>
                                            <td style={{ paddingLeft: '50px', fontWeight: 'bold' }}>Miscellaneous Credit: {state.selectedMember?.miscellaneousCredit > 0 ? <span style={{ color: 'green' }}>{currencyFormatter.format(state.selectedMember?.miscellaneousCredit)}</span> : <span>$0.00</span>}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row padding-top-15 ml-3 mr-3">
                    <div className="card-container">
                        <Tabs defaultActiveKey='tab-member-info' tabPosition='top' type='card'>
                            <TabPane tab='Member Info' key='tab-member-info'>
                                <MemberInfoTab selectedMemberId={state.selectedMemberId} selectedIdx={state.selectedIdx} selectedMember={state.selectedMember} />
                            </TabPane>

                            <TabPane tab='Member Notes' key='tab-member-notes'>
                                <MemberNotesTab selectedMemberId={state.selectedMemberId} />
                            </TabPane>

                            <TabPane tab='Member Date Events' key='tab-member-date-events'>
                                <MemberDateEventsTab selectedMemberId={state.selectedMemberId} />
                            </TabPane>

                            <TabPane tab='Payment History' key='tab-payment-history'>
                                <MemberPaymentsTab selectedMemberId={state.selectedMemberId} member={state.selectedMember} />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}