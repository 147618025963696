import { Button, Form, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { NumericFormat } from 'react-number-format';

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    type,
    index,
    from,
    options,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState((record && record[dataIndex]) || '');
    
    useEffect(() => {
        save();
    }, [value]);

    const toggleEdit = () => {
        setEditing(!editing);
    };
    async function save() {
        try {
            toggleEdit();
            if (handleSave) {
                handleSave({
                    ...record,
                    [dataIndex]: value,
                });
            }
            
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    function handleChange(e) {
        setValue(e.target.type === 'checkbox' ? e.target.checked : e.target.value);
    }

    function handleDollarChange(values) {
        setValue(values.floatValue);
    }

    let childNode = children;
    var maxValue = null;

    if (dataIndex === 'pay') {
        var obj = type.find(t => t.feeName === record.feeName);
        type = obj?.type;

        if (obj?.maxValue) {
            maxValue = record[obj.maxValue];
            if (maxValue < 0) {
                maxValue = maxValue * -1;
            }
        }
    }

    

    if (editable &&
        (
            !from ||
            (from === 'dues' && index === 0 && dataIndex === 'monthsPaid') ||
            (from === 'dues' && index > 0 && dataIndex === 'pay')
        )) {
        switch (type) {
            case 'dropdown':
                childNode = (
                    <select className="form-control-custom" value={record[dataIndex]} onChange={handleChange} onBlur={save}>
                        {options.map((option, idx) => <option key={idx} value={option}>{option}</option>)}
                    </select>
                );
                break;
            case 'date':
                childNode = (
                    <DatePicker
                        selected={value ? Moment(value).toDate() : null}
                        onChange={date => setValue(date)}
                        className="form-control-custom"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="MM/dd/yyyy"
                    />
                );
                break;
            case 'checkbox':
                childNode = (
                    <input type="checkbox" className="custom-control-input" onChange={handleChange} onBlur={save} />
                );
                break;
            case 'numeric':
                childNode = (
                    <input type="number" className="form-control-custom" value={value} onChange={handleChange} onBlur={save} step="0.01" max={record.feeRate*-1} />
                );
                break;
            case 'dollar':
                childNode = (
                    <NumericFormat className="form-control-custom" onValueChange={handleDollarChange} onBlur={save} prefix={'$'} isAllowed={(values) => {
                        const { floatValue } = values;
                        return maxValue && floatValue ? floatValue <= maxValue : true;
                    }} />
                );
                break;
            case 'input':
            default:
                childNode = (
                    <input type="text" className="form-control-custom" value={value} onChange={handleChange} onBlur={save} />
                );
                break;
        }
    } else {
        //<div
        //    className="editable-cell-value-wrap"
        //    style={{
        //        paddingRight: 24,
        //    }}
        //>
        //    {children}
        //</div>
    }
    return <td {...restProps}>{childNode}</td>;
};
export default function AddableTable(props) {
    const [dataSource, setDataSource] = useState([]);
    const [count, setCount] = useState(1);
    useEffect(() => {
        setDataSource(props.dataSource);
    }, [props.dataSource]);

    const handleAdd = () => {

        var object = props.columns.reduce(
            (obj, item) => Object.assign(obj, !item.ignore && { [item.dataIndex]: '' }), {});

        const newData = {
            ...object,
            key: count
        };
        props.updateDataSource([...dataSource, newData]);

        setCount(count + 1);
    };
    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        props.updateDataSource(newData);
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = props.columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record, idx) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                type: col.type,
                index: idx,
                from: props.from || null,
                options: col.options || null,
                handleSave,
            }),
        };
    });
    return (
        <div>
            {props.tableMessage}
            {!props.hideAdd &&
                <Button
                    onClick={handleAdd}
                    type="primary"
                    style={{
                        marginBottom: 16,
                    }}
                    className="float-end"
                >
                    <i className="far fa-plus"></i>
                </Button>
            }
            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={!props.hidePagination}
            />
        </div>
    );
};