import React, { useEffect, useState, useRef } from 'react';
import { Input, AutoComplete, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { highlightContent } from '../../helpers/Highlight';
import Axios from '../../config/axios';
import { useNavigate } from "react-router-dom";

const antIcon = <LoadingOutlined spin />;

function CustomSearch(props) {
    const navigate = useNavigate();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    var timeout = useRef(null);

    const [state, setState] = useState({
        results: [],
        searchValue: '',
        open: false
    });

    const [searchResults, setSearchResults] = useState({
        results: [],
        loaded: false
    });

    useEffect(() => {
        setState({
            ...state,
            searchValue: ''
        });

        setSearchResults({
            results: [],
            loaded: true
        });
    }, [props]);

    function performSearch(value) {
        Axios.get(`/api/GetSearchResults`, {
            params: {
                searchValue: value
            }
        }).then(response => {
            let results = [];

            if (response.data.members?.length > 0) {
                var customerObj = {
                    label: 'Members',
                    options: response.data.members.map(item => renderMember(item, value))
                }

                results.push(customerObj);
            }

            setSearchResults({
                results,
                loaded: true
            });

        }).catch(error => {
            console.log(error);
        });
    }

    function renderMember(member, searchValue) {
        console.log(member);
        console.log(searchValue);

        var highlight = highlightContent(member.lastName + ', ' + member.firstName + ": SSN (" + member.ssn + ") Card # (" + member.cardClientNo + ")", searchValue);

        return {
            value: member.members_ID,
            memberid: member.members_ID,
            type: 'member',
            membername: member.lastName + ', ' + member.firstName,
            label: (
                <div>
                    {highlight.map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>)}
                </div>
            )
        }
    }

    function handleSelect(option, searchItem) {
        console.log(searchItem);
        console.log(option);

        setState({
            ...state,
            searchValue: '',
            open: false
        });


        setSearchResults({
            results: []
        });

        navigate("/member-dashboard", { state: { selectedMemberId: searchItem.value, selectedMemberName: searchItem.membername } });
    }

    function handleClickSearch(value, event) {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }

        setState({
            ...state,
            searchValue: value,
            open: true
        });

        if (value) {
            timeout.current = setTimeout(() => performSearch(value), 500);
        }

        //if (!event) {
        //    if (!value) {
        //        setState({
        //            ...state,
        //            open: false,
        //            searchValue: value
        //        });
        //    } else {
        //        setState({
        //            ...state,
        //            searchValue: value
        //        });
        //    }
        //}
        //if (event) {
        //    setSearchResults({
        //        ...searchResults,
        //        results: [],
        //        loaded: false
        //    });

        //    performSearch(value);

        //    setState({
        //        ...state,
        //        open: true
        //    });
        //}
    }

    var results = state.results;

    const renderTitle = (title) => (
        <span>
            {title}
        </span>
    );

    const renderItem = (item) => ({
        value: item.companyId.toString(),
        label: (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {item.name}
            </div>
        ),
    });

    let list = [];
    results.forEach(c => {
        list.push(renderItem(c));
    });

    const options = [
        {
            label: renderTitle('Customers'),
            options: list,
        },
    ];

    return (
        <>
            <AutoComplete
                dropdownClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={500}
                style={{
                    width: '100%',
                }}
                onSearch={handleClickSearch}
                options={searchResults.results}
                notFoundContent="No Results"
                onSelect={handleSelect}
                value={state.searchValue}
                open={state.open}
                onBlur={() => setState({ ...state, open: false })}
                notFoundContent={searchResults.loaded ? "No Results Found" : <div><Spin indicator={antIcon} /> Searching...</div>}
            >
                <Input.Search size="medium" enterButton={true} onSearch={handleClickSearch} placeholder={'Search Last Name, First Name, SSN, Card #'} />
                </AutoComplete>
        </>
    );
}

export default CustomSearch;