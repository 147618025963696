import React, { useState, useEffect } from 'react';
import logo from '../../img/logo.png';
import './login.css';
import { toast } from '@rickylandino/react-messages';
import Axios from '../../config/axios';
import Globals from '../../config/globals';
import { UserOutlined, LoadingOutlined, LockOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Spin, Alert } from 'antd';
import { useForm, Controller } from "react-hook-form";
import { validateUser } from '../../services/TokenService';
import { useNavigate } from "react-router-dom";
import { sendResetPasswordLink, userInfo, userPermissions } from '../../services/UsersService';
import { v4 as uuidv4 } from 'uuid';
import { getIPv4, insertAuditLogin } from '../../services/AuditLoginService';

export function Login(props) {
    const [loading, setLoading] = useState(false);

    const [state, setState] = useState({
        formFields: {
            userid: '',
            password: ''
        },
        loading: false
    });

    const { register, watch, setValue, getValues, control } = useForm();
    const navigate = useNavigate();

    useEffect(() => {
        //setValue('userid', 'aberkmoes@local777.com');
        //setValue('password', 'aberkmoes@2023!');
        setValue('userid', '');
        setValue('password', '');
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();

        setLoading(true);

        console.log(getValues());

        var ipv4 = "";//await getIPv4();
        validateUser(getValues().userid, getValues().password).then(data => {
            console.log(data);
            if (data) {
                setLoading(false);

                //toast.success("Login Successful for " + data.username);

                if (data?.token) {
                    Globals.bearer = data.token;
                }
                if (data?.user) {
                    console.log(data.user.user);
                    Globals.userInfo = data.user.user;
                    userInfo.next(data.user.user);
                }

                let auditLoginItem = {
                    auditLogin_ID: uuidv4(),
                    users_ID: data?.users_ID,
                    ipv4,
                    bearer: data.token
                };

                let userInfo = Globals.userInfo;
                userInfo.bearer = data.token;
                userInfo.fullName = data.fullName;
                userInfo.isLoggedIn = true;
                userInfo.username = data.username;
                userInfo.userType = data.userType;
                Globals.userInfo = userInfo;
                window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

                insertAuditLogin(auditLoginItem);
                window.sessionStorage.setItem("uid", auditLoginItem.auditLogin_ID);

                navigate("/member-manager", { replace: true });
            }
        }).catch(error => {
            setLoading(false);
            console.log(error);
            toast.error("Invalid Login Credentials");
        });
    }

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            formFields: { ...state.formFields, [name]: value }
        });
    }

    return (
        //<div className="background">
        //    <div className="blankpage-form-field">
        //        <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
        //            <img src={logo} height="90px" alt="Local 777 Member Manager" aria-roledescription="logo" />
        //        </div>
        //        <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">
        //            <form>
        //                <div className="form-group">
        //                    <label className="form-label" htmlFor="username">Username</label>
        //                    <input type="text" id="userid" className="form-control-custom" name="userid" value={state.formFields.userid} onChange={handleInputChange} />
        //                    <span className="help-block">
        //                        Your username
        //                    </span>
        //                </div>
        //                <div className="form-group">
        //                    <label className="form-label" htmlFor="password">Password</label>
        //                    <input type="password" id="password" name="password" value={state.formFields.password} onChange={handleInputChange} className="form-control-custom" />
        //                    <span className="help-block">
        //                        Your password
        //                    </span>
        //                </div>

        //                {state.loading && <Spin indicator={antIcon} />}
        //                <button className="btn btn-submit float-right" onClick={handleSubmit}>Secure login</button>
        //            </form>
        //        </div>
        //    </div>

        //    {/*<Modal title="Basic Modal" visible={this.state.showTfaSetup} onCancel={() => this.setState({ showTfaSetup: false })}>*/}
        //    {/*    <TFASetup user={this.state.user} verify2FA={this.verify2FA} />*/}
        //    {/*</Modal>*/}
        //</div>
        <div className="background">
            <div className="blankpage-form-field">
                <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
                    <img src={logo} height="65px" alt="Logo" aria-roledescription="logo" />
                </div>
                <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <span className="help-block">
                            Your username
                        </span>
                        <Controller
                            name="userid"
                            control={control}
                            render={({ field }) => <Form.Item>
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} onChange={(e) => setValue('userid', e.target.value)} placeholder="User ID" value={getValues().userid} />
                            </Form.Item>
                            }
                        />

                        <span className="help-block">
                            Your password
                        </span>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => <Form.Item>
                                <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" onChange={(e) => setValue('password', e.target.value)} placeholder="Password" value={getValues().password} />
                            </Form.Item>
                            }
                        />

                        <Form.Item>
                            {/*<Form.Item name="remember" noStyle>*/}
                            {/*    <Checkbox>Remember me</Checkbox>*/}
                            {/*</Form.Item>*/}
                        </Form.Item>

                        <Form.Item>
                            {loading ?
                                <Spin indicator={antIcon} />
                                :
                                <Button type="primary" htmlType="submit" className="login-form-button" onClick={handleSubmit}>
                                    Secure Log in
                                </Button>
                            }
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
}