import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useForm, Controller } from "react-hook-form";
import Globals from '../../config/globals';
import { Modal, message } from 'antd';
import { Badge, Select, Spin, Alert, Table, Radio } from 'antd';
import SingleDateEventModal from './SingleDateEventModal';
import PaymentsSlider from './PaymentsSlider';
import { getMemberPaymentInformation, renderReceipt } from '../../services/PaymentsService';

export default function MemberPaymentsTab(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        selectedMemberId: props.selectedMemberId,
        showSingleDateEventModal: false,
        dataLoaded: false,
        paymentDataLoaded: false,
        noteEditMode: 'Add',
        charges: [],
        chargePayments: [],
        chargesSummaryList: [],
        summaryOfFeesList: [],
        summaryOfAdjustmentsList: [],
        totalPayment: 0,
        totalFeesAmount: 0,
        totalAdjustmentsAmount: 0,
        paymentsLoading: true,
        selectedEvent: {},
        paymentType: 'postedPayments',
        postedPayments: [],
        unpostedPayments: []
    });

    const [showPaymentsSlider, setShowPaymentsSlider] = useState(false);

    const dateFormat = 'MM/DD/YYYY';

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const RadioGroup = Radio.Group;

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    useEffect(() => {
        if (state.charges?.length > 0) {
            setState({ ...state, dataLoaded: true });
        }
    }, [state.charges]);

    useEffect(() => {
        GetMemberCharges();
    }, [props]);

    function GetMemberCharges() {

        getMemberPaymentInformation(props.selectedMemberId).then(data => {
            let postedPayments = [];
            let unpostedPayments = [];
            var charges;
            var i;

            let chargesList = data.chargesList;
            let chargesSummaryList = data.chargesSummary;
            let chargesDetails = data.chargesDetails;

            console.log(chargesDetails);

            for (i = 0; i < chargesList.length; i++) {
                if (chargesList[i].isPosted === 1) {
                    postedPayments.push(chargesList[i]);
                }
                else {
                    unpostedPayments.push(chargesList[i]);
                }
            }

            let totalPayment = 0;
            let summaryOfFeesList = [];
            let summaryOfAdjustmentsList = [];
            let totalFeesAmount = 0;
            let totalAdjustmentsAmount = 0;

            for (let i = 0; i < chargesSummaryList.length; i++) {
                if (chargesSummaryList[i].detailChargeAmount < 0) {
                    summaryOfAdjustmentsList.push(chargesSummaryList[i]);
                    totalAdjustmentsAmount += chargesSummaryList[i].detailChargeAmount;
                }
                else {
                    summaryOfFeesList.push(chargesSummaryList[i]);
                    totalFeesAmount += chargesSummaryList[i].detailChargeAmount;
                }

                totalPayment = totalPayment + chargesSummaryList[i].detailChargeAmount;
            }

            if (state.paymentType === 'postedPayments') {
                charges = postedPayments;
            }
            else {
                charges = unpostedPayments;
            }

            setState({
                ...state,
                charges: charges,
                summaryOfFeesList: summaryOfFeesList,
                summaryOfAdjustmentsList: summaryOfAdjustmentsList,
                totalAdjustmentsAmount: totalAdjustmentsAmount,
                totalFeesAmount: totalFeesAmount,
                totalPayment: totalPayment,
                dataLoaded: true,
                showSingleDateEventModal: false,
                postedPayments,
                unpostedPayments
            });
        }).catch(error => {
            console.log(error);
        });

        //Axios.get(`/api/GetMemberCharges`, {
        //    params: {
        //        members_ID: props.selectedMemberId
        //    }
        //}).then(response => {
        //    let chargesList = response.data;

        //    Axios.get(`/api/GetMemberChargesSummary`, {
        //        params: {
        //            members_ID: props.selectedMemberId
        //        }
        //    }).then(response => {
        //        let chargesSummaryList = response.data;

        //        let totalPayment = 0;
        //        let summaryOfFeesList = [];
        //        let summaryOfAdjustmentsList = [];
        //        let totalFeesAmount = 0;
        //        let totalAdjustmentsAmount = 0;

        //        for (let i = 0; i < chargesSummaryList.length; i++) {
        //            if (chargesSummaryList[i].detailChargeAmount < 0) {
        //                summaryOfAdjustmentsList.push(chargesSummaryList[i]);
        //                totalAdjustmentsAmount += chargesSummaryList[i].detailChargeAmount;
        //            }
        //            else {
        //                summaryOfFeesList.push(chargesSummaryList[i]);
        //                totalFeesAmount += chargesSummaryList[i].detailChargeAmount;
        //            }

        //            totalPayment = totalPayment + chargesSummaryList[i].detailChargeAmount;
        //        }

        //        setState({
        //            ...state,
        //            charges: chargesList,
        //            summaryOfFeesList: summaryOfFeesList,
        //            summaryOfAdjustmentsList: summaryOfAdjustmentsList,
        //            totalAdjustmentsAmount: totalAdjustmentsAmount,
        //            totalFeesAmount: totalFeesAmount,
        //            totalPayment: totalPayment,
        //            dataLoaded: true,
        //            showSingleDateEventModal: false
        //        });
        //    }).catch(error => {
        //        console.log(error);
        //    });

        //}).catch(error => {
        //    console.log(error);
        //});
    }

    function hidePane() {
        setState({
            ...state,
            showSingleDateEventModal: false
        });
    }

    const columns = [
        {
            dataIndex: 'memberCharges_ID',
            title: 'Receipt',
            key: 'memberCharges_ID',
            width: 150,
            align: 'center'
        }, {
            dataIndex: 'paidthruDate',
            title: 'Paid Thru Date',
            key: 'paidthruDate',
            width: 150,
            align: 'center',
            render: (paidthruDate) => {
                return (
                    <div>
                        {paidthruDate === null ? '' : Moment(dateWithNoTimezone(paidthruDate)).format("L")}
                    </div>
                );
            }
        }, {
            dataIndex: 'postDate',
            title: 'Post Date',
            key: 'postDate',
            width: 150,
            align: 'center',
            render: (postDate) => {
                return (
                    <div>
                        {postDate === null ? '' : Moment(dateWithNoTimezone(postDate)).format("L")}
                    </div>
                );
            }
        }, {
            dataIndex: 'isVoided',
            title: 'Payment Voided',
            key: 'isVoided',
            width: 150,
            align: 'center',
            render: (isVoided) => {
                return (
                    <div>
                        {isVoided === 1 ? <span dangerouslySetInnerHTML={{ __html: '<span style="color:red">VOIDED</span>' }}></span> : 'No'}
                    </div>
                );
            }
        }, {
            dataIndex: 'chargeAmount',
            title: 'Payment Amount',
            key: 'chargeAmount',
            width: 150,
            align: 'center',
            render: (chargeAmount) => {
                return (
                    currencyFormatter.format(chargeAmount)
                );
            }
        }, {
            title: 'Charge Summary',
            dataIndex: 'chargeDetailsSummaryList',
            key: 'chargeDetailsSummaryList',
            render: (chargeDetailsSummaryList, record, idx) => getChargeDetailsText(chargeDetailsSummaryList, record, idx)
        }, {
            dataIndex: 'comments',
            title: 'Comments',
            key: 'comments',
        }, {
            dataIndex: 'createdBy',
            title: 'Payment Entered By',
            key: 'createdBy',
        },
        {
            dataIndex: 'receipt',
            isDummyField: true,
            title: 'View Receipt',
            render: cell => <i id="viewReceipt" className="fas fa-edit fa-1x ml-3 text-center hover"></i>
        }
    ].filter(item => !item.hidden);

    function getChargeDetailsText(chargeDetailsSummaryList, record, idx) {
        let myChargeDetailsSummaryStr = '';

        for (var j = 0; j < chargeDetailsSummaryList.length; j++) {
            myChargeDetailsSummaryStr += chargeDetailsSummaryList[j].feeDisplayName + ': ' + currencyFormatter.format(chargeDetailsSummaryList[j].detailChargeAmount) + '<br />';
            //myChargeDetailsStr += chargeDetailsList[j].feeDisplayName + ': ' + chargeDetailsList[j].detailChargeAmount + '<br />';
        }

        return (
            <span dangerouslySetInnerHTML={{ __html: myChargeDetailsSummaryStr }}></span>
        );
    }

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });


    function handleAddNewPayment() {
        setState({
            ...state,
            selectedItemId: null,
            selectedIdx: null,
            eventEditMode: 'Add',
            selectedEvent: null,
            showSingleDateEventModal: true
        });
    }

    function handleEditEvent(e, record) {
        setState({
            ...state,
            selectedItemId: record.memberCharges_ID,
            //selectedIdx: rowIndex,
            eventEditMode: 'Edit',
            selectedEvent: record,
            showSingleDateEventModal: true
        });
    }


    function DeleteCustomerNote(itemId) {
        //let postdata = {};

        //postdata.itemId = itemId;
        //Axios.post('/api/DeleteCustomerNote', postdata
        //).then(response => {
        //    let filterednotes = state.notes.filter(item => item.itemId !== itemId)

        //    setState({
        //        ...state,
        //        notes: filterednotes,
        //        dataLoaded: true,
        //        showNoteDetailsSlider: false
        //    });

        //    toast.success('Note deleted successfully');
        //}).catch(error => {
        //    console.log(error);
        //});
    }

    function updateTableDisplay(events, idxToDirectTo = null) {
        setSelectedRowKeys([events[idxToDirectTo].memberCharges_ID]);

        setState({
            ...state,
            events: [...events],
            selectedItemId: events[idxToDirectTo].memberCharges_ID,
            showSingleDateEventModal: false
        });
    }

    function disableSingleDateEventModal() {
        setState({
            ...state,
            showSingleDateEventModal: false
        });
    }

    async function reloadDateEventsFromDB() {
        GetMemberCharges();
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    //https://codesandbox.io/s/naughty-wescoff-w1jhd?file=/index.js:329-428

    const handleExpand = (expanded, record) => {
        const keys = [];

        setState({
            ...state,
            chargePayments: [],
            paymentsLoading: true
        });

        if (expanded) { 
            keys.push(record.memberCharges_ID);

            setTimeout(() => {
                Axios.get(`/api/GetMemberChargePayments`, {
                    params: {
                        memberCharges_ID: record.memberCharges_ID
                    }
                }).then(response => {
                    let paymentsList = response.data;

                    setState({
                        ...state,
                        chargePayments: paymentsList,
                        paymentsLoading: false
                    });

                }).catch(error => {
                    console.log(error);
                });
            }, 500);
        }
        setExpandedRowKeys(keys);
    };

    const expandedRowRender = record => {
        const columns = [
            {
                dataIndex: 'dateReceived',
                title: 'Received Date',
                key: 'dateReceived',
                render: (dateReceived) => {
                    return (
                        <div>
                            {dateReceived === null ? '' : Moment(dateWithNoTimezone(dateReceived)).format("L")}
                        </div>
                    );
                }
            }, {
                dataIndex: 'createdDateTime',
                title: 'Deposit Date',
                key: 'createdDateTime',
                render: (createdDateTime) => {
                    return (
                        <div>
                            {createdDateTime === null ? '' : Moment(dateWithNoTimezone(createdDateTime)).format("L")}
                        </div>
                    );
                }
            }, {
                title: 'Payment Type',
                dataIndex: 'paymentType',
                key: 'paymentType',
            }, {
                title: 'Payment ID',
                dataIndex: 'paymentID',
                key: 'paymentID',
            }, {
                dataIndex: 'checkDate',
                title: 'Check Date',
                key: 'checkDate',
                render: (checkDate) => {
                    return (
                        <div>
                            {checkDate === null ? '' : Moment(dateWithNoTimezone(checkDate)).format("L")}
                        </div>
                    );
                }
            }, {
                title: 'Cash Sheet',
                dataIndex: 'cashSheet',
                key: 'cashSheet',
            }, {
                dataIndex: 'paymentAmount',
                title: 'Payment Amount',
                key: 'paymentAmount',
                render: (paymentAmount) => {
                    return (
                        currencyFormatter.format(paymentAmount)
                    );
                }
            }
        ];

        return (
            <Table
                loading={state.paymentsLoading}
                columns={columns}
                dataSource={state.chargePayments}
                pagination={false}
            />
        );
    };

    function onRadioChange(e) {
        var charges;

        if (e.target.value === 'postedPayments') {
            charges = state.postedPayments;
        }
        else {
            charges = state.unpostedPayments;
        }

        setState({
            ...state,
            paymentType: e.target.value,
            charges
        });
    }

    function handleAddNewPayment(e) {

        if (state.unpostedPayments.length === 0) {
            setShowPaymentsSlider(true)

            setState({
                ...state,
            });
        }
        else {
            toast.info('Edit unposted payment');
            return;
        }
    }

    return (
        <Fragment>
            <div className="form-group col-12 pb-3">
                <span className="color-dark-blue"><label className="form-label">Member Payment History</label></span>
                <button className="btn btn-submit margin-left-15 float-right" onClick={handleAddNewPayment}>{state.unpostedPayments.length === 0 ? 'Add New Payment' : 'Edit Unposted Payment'}</button>
            </div>

            <div className="form-group col-lg-8" style={{ marginTop: -15 }}>
                <RadioGroup name={'rb1'} onChange={onRadioChange} style={{ display: 'flex' }} className="form-label" defaultValue='postedPayments' value={state.paymentType || undefined}>
                    <Radio style={radioStyle} value='postedPayments' checked={state.paymentType === 'postedPayments'}>Posted Payments</Radio>
                    <Radio style={radioStyle} value='unpostedPayments' checked={state.paymentType === 'unpostedPayments'}>Unposted Payments</Radio>
                </RadioGroup>
            </div>

            <div className="row">
                <div className="col-sm-10 col-md-10 col-lg-10">
                    <Spin spinning={!state.dataLoaded}>
                        <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                            <Alert
                                message="Loading Payment History"
                                description="Please stand by while we retrieve payment history for the selected member"
                                type="info"
                            />
                        </div>
                        {state.dataLoaded &&
                            <Fragment>
                                {state.charges.length === 0 ?
                                    <div style={{ fontSize: '18px', color: 'red' }}>{state.paymentType === 'postedPayments' ? 'No Posted Payment Hostory Found' : 'No Unposted Payments Found'}</div>
                                    :
                                    <Fragment>
                                        <div>
                                            <Table className="custom-ant-selection"
                                                rowKey={item => item.memberCharges_ID}
                                                rowSelection={rowSelection}
                                                bordered
                                                dataSource={state.charges}
                                                columns={columns}
                                                pagination={{
                                                    defaultPageSize: 10,
                                                    showSizeChanger: true,
                                                    pageSizeOptions: ['10', '25', '50', '100'],
                                                    showTotal: (total, range) => (
                                                        <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                            Showing {range[0]}-{range[1]} of {total}
                                                        </span>
                                                    )
                                                }}
                                                expandedRowRender={expandedRowRender}
                                                onExpand={handleExpand}
                                                expandedRowKeys={expandedRowKeys}
                                                onRow={(record) => {
                                                    return {
                                                        onClick: (e) => {
                                                            if (e?.target?.id === 'viewReceipt') {
                                                                renderReceipt(record?.memberCharges_ID);
                                                            } else {
                                                                if (record?.membercharge?.memberCharges_ID) {
                                                                    let selRows = [record.membercharge.memberCharges_ID];
                                                                    setSelectedRowKeys([...selRows]);
                                                                }
                                                            }
                                                        },
                                                        onDoubleClick: (e) => {
                                                            handleEditEvent(e, record);
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </Spin>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2 text-left">
                    <div>
                        <br /> <br /> <br />
                        <table style={{ border: 'solid thin navy', padding: '10px' }}>
                            <tbody>
                                <tr><td colSpan="2" style={{ paddingLeft: '10px', paddingRight: '10px', fontWeight: 'bold' }}>Total Summary of Fees</td></tr>
                                {state.summaryOfFeesList.map((feeItem, idx) => <tr><td style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }} colSpan="2">{feeItem.feeDisplayName}: <strong>{currencyFormatter.format(feeItem.detailChargeAmount)}</strong></td></tr>)}
                                <tr><td colSpan="2" style={{ paddingLeft: '10px', paddingRight: '10px', fontWeight: 'bold' }}>Total Fees Amount: {currencyFormatter.format(state.totalFeesAmount)}</td></tr>
                                <tr><td colSpan="2" align="center">----------</td></tr>
                                <tr><td colSpan="2" style={{ paddingLeft: '10px', paddingRight: '10px', fontWeight: 'bold' }}>Total Summary of Adjustment</td></tr>
                                {state.summaryOfAdjustmentsList.map((feeItem, idx) => <tr><td style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }} colSpan="2">{feeItem.feeDisplayName}: <strong>{currencyFormatter.format(feeItem.detailChargeAmount)}</strong></td></tr>)}
                                <tr><td colSpan="2" style={{ paddingLeft: '10px', paddingRight: '10px', fontWeight: 'bold' }}>Total Adjustments Amount: {currencyFormatter.format(state.totalAdjustmentsAmount)}</td></tr>
                                <tr><td colSpan="2" align="center">----------</td></tr>
                                <tr><td colSpan="2" style={{ paddingLeft: '10px', paddingRight: '10px', fontWeight: 'bold' }}><strong>Total Payment Amount: {currencyFormatter.format(state.totalPayment)}</strong></td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {showPaymentsSlider &&
                <PaymentsSlider memberID={props.selectedMemberId} showPane={showPaymentsSlider} hidePane={() => setShowPaymentsSlider(false)} member={props.member} refreshList={GetMemberCharges} />
            }
        </Fragment>
    );
}