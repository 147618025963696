import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Fragment } from 'react';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import { Input, AutoComplete, Skeleton, Spin, Alert, Modal, Table, Select } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import Moment from 'moment';
import AddNewMemberSlider from './AddNewMemberSlider';
import { useForm, Controller } from "react-hook-form";

//import MemberDetailsSlider from './MemberDetailsSlider';
//import MemberContactsSlider from './MemberContactsSlider';

import produce from "immer"

//let fnameFilter;
//let lnameFilter;
//let minitialFilter;
//let suffixFilter;
//let ssnFilter;
//let cardnumFilter;
//let cityFilter;
//let stateFilter;
//let statusFilter;
//let classFilter;
//let rateclassFilter;
//let apprenticelevelFilter;
//let tradeFilter;
//let tradelevelFilter;

export default function MemberManager() {
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        members: [],
        selectedMember: null,
        dataLoaded: false,
        formFields: {},
        noMembers: true,
        selectedMemberStatus: location?.state?.selectedMemberStatus || 'Active',
        selectedIdx: null,
        showAddNewMemberSlider: false,
        showMemberContactsSlider: false,
        MemberID: null,
        MemberName: '',
        selectedMemberId: null,
        memberManagerInitialized: false,
        filteredMembers: [],
        filteredMatchedMembers: [],
        searchValue: '',
        currentPage: 1,
        currentPageSize: 10
    });

    //const [statusListData, setStatusListData] = useState([]);
    //const [classListData, setClassListData] = useState([]);
    //const [rateclassListData, setRateClassListData] = useState([]);
    //const [apprenticeclasslevelListData, setApprenticeClassLevelListData] = useState([]);
    //const [tradeListData, setTradeListData] = useState([]);
    //const [tradelevelListData, setTradeLevelListData] = useState([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    const { register, handleSubmit, setValue, getValues, control } = useForm();

    const [members, setMembers] = useState([]);
    const [filteredMembers, setfilteredMembers] = useState([]);
    const [filteredMatchedMembers, setfilteredMatchedMembers] = useState([]);

    useEffect(() => {
        var myPageSize;
        var myPageIndex = 1;
        var myFilteredMatchMembers;

        if (location.state === null || location.state.filteredMatchedMembers.length === 0) {
            LoadMembers();
        }
        else {
            let selRows = [location.state.selectedMemberId];
            setSelectedRowKeys([...selRows]);

            //undefined since we are hitting the customers list after coming from a top nav search
            if (location.state.currentPageSize === undefined) {
                myPageSize = state.currentPageSize;
            }
            else {
                myPageSize = location.state.currentPageSize;
            }

            myFilteredMatchMembers = [...location.state.filteredMatchedMembers];

            //now go calculate the page to jump to by finding the selectedCustNum and the page size
            for (let i = 0; i < myFilteredMatchMembers.length; i++) {
                if (myFilteredMatchMembers[i].member.members_ID === location.state.selectedMemberId) {
                    Axios.get(`/api/GetMemberById`, {
                        params: {
                            memberID: myFilteredMatchMembers[i].member.members_ID
                        }
                    }).then(response => {
                        myFilteredMatchMembers[i] = response.data;

                        setMembers(location.state.members);
                        setfilteredMembers(location.state.filteredMembers);
                        setfilteredMatchedMembers([...myFilteredMatchMembers]);

                        setState({
                            ...state,
                            dataLoaded: true,
                            selectedMemberStatus: location.state.selectedMemberStatus,
                            searchValue: location.state.searchValue,
                            currentPage: myPageIndex,
                            currentPageSize: myPageSize
                        });

                    }).catch(error => {
                        console.log(error);
                    });

                    myPageIndex = Math.floor(i / myPageSize) + 1
                    break;
                }
            }
        }
    }, []);

    function LoadMembers() {
        var myPageSize;
        var myPageIndex = 1;

        Axios.get(`/api/GetAllMembers`, {
            params: {
                //status: 'Active'
            }
        }).then(response => {
            let membersList = response.data;

            //init to only show 'Active' members
            var filteredMembers = [];
            for (var i = 0, l = membersList.length; i < l; i++) {
                if (membersList[i].member.status === state.selectedMemberStatus) {
                    filteredMembers.push(membersList[i]);
                }
            }

            setMembers(membersList);
            setfilteredMembers(filteredMembers);
            setfilteredMatchedMembers(filteredMembers);

            if (location?.state?.selectedMemberId !== undefined) {
                let selRows = [location.state.selectedMemberId];
                setSelectedRowKeys([...selRows]);

                //undefined since we are hitting the customers list after coming from a top nav search
                if (location.state.currentPageSize === undefined) {
                    myPageSize = state.currentPageSize;
                }
                else {
                    myPageSize = location.state.currentPageSize;
                }

                //now go calculate the page to jump to by finding the selectedMemberId and the page size
                for (let i = 0; i < filteredMembers.length; i++) {
                    if (filteredMembers[i].member.members_ID === location.state.selectedMemberId) {
                        myPageIndex = Math.floor(i / myPageSize) + 1
                        break;
                    }
                }
            }

            setState({
                ...state,
                currentPage: myPageIndex,
                currentPageSize: myPageSize,
                dataLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleMemberStatusChange(e) {
        setState({
            ...state,
            selectedMemberStatus: e.target.value
        });
    }

    function handleDelete(Member) {
        confirm({
            title: "You are about to permanently delete this Member",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                //this uses the ignore field in the AccountsModel to pass in the selected filterType to be used when returning the new list of Accounts
                Member.MemberStatus = state.selectedMemberStatus;

                Axios.post(`/api/DeleteMember`, Member
                ).then(response => {
                    if (response.data) {
                        toast.success("Member has been deleted");

                        setState({
                            ...state,
                            Members: response.data,
                            dataLoaded: true
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    async function updateMembers(members, newID) {

    }

    async function updateMembers(members, newID)
    {
        //await setState({ ...state, dataLoaded: false });

        //console.log(members);
        //console.log(newID);

        //const idxToDirectTo = members.findIndex(m => m.member.members_ID === newID);
        ////members[selectedIdx] = getValues().formFields;

        //console.log(idxToDirectTo);
        ////GetAllMembers();

        ////await setState({ ...state, dataLoaded: false });

        //if (idxToDirectTo !== null) {
        //    const page = idxToDirectTo > 0 ? Math.ceil(idxToDirectTo / tableControl.sizePerPage) : 1;

        //    await setTableControl({ ...tableControl, page });
        //} else {
        //    idxToDirectTo = 0
        //}

        //setState({
        //    ...state,
        //    members,
        //    selectedMember: members[idxToDirectTo],
        //    dataLoaded: true,
        //    showAddNewMemberSlider: false
        //});
    }

    function handleAddMember()
    {
        setState({
            ...state,
            selectedMemberId: null,
            showAddNewMemberSlider: true,
            selectedIdx: null,
            loading: false
        });
    }

    function handleTableChange(type, { page, sizePerPage }) {

    }

    async function updateTableDisplay(members, idxToDirectTo = null) {
        //await setState({ ...state, dataLoaded: false });

        //if (idxToDirectTo !== null) {
        //    const page = idxToDirectTo > 0 ? Math.ceil(idxToDirectTo / tableControl.sizePerPage) : 1;

        //    await setTableControl({ ...tableControl, page });
        //} else {
        //    idxToDirectTo = 0
        //}

        //setState({
        //    ...state,
        //    members,
        //    selectedMember: members[idxToDirectTo],
        //    dataLoaded: true,
        //    showAddNewMemberSlider: false
        //});
    }

    //const StatusList = statusListData.reduce((a, v) => ({ ...a, [v]: v }), { '': 'All' });
    //const ClassList = classListData.reduce((a, v) => ({ ...a, [v]: v }), { '': 'All' });
    //const RateClassList = rateclassListData.reduce((a, v) => ({ ...a, [v]: v }), { '': 'All' });
    //const ApprenticeClassLevelList = apprenticeclasslevelListData.reduce((a, v) => ({ ...a, [v]: v }), { '': 'All' });
    //const TradeList = tradeListData.reduce((a, v) => ({ ...a, [v]: v }), { '': 'All' });
    //const TradeLevelList = tradelevelListData.reduce((a, v) => ({ ...a, [v]: v }), { '': 'All' });

    const columns = [
        {
            dataIndex: ['member', 'members_ID'],
            title: 'members_ID',
            key: 'member.members_ID',
            hidden: true
        }, {
            dataIndex: ['member', 'lastName'],
            title: 'Last Name',
            key: 'member.lastName'
        }, {
            dataIndex: ['member', 'firstName'],
            title: 'First Name',
            key: 'member.firstName'
        }, {
            dataIndex: ['member', 'middleInitial'],
            title: 'Initial',
            key: 'member.middleInitial'
        }, {
            dataIndex: ['member', 'suffix'],
            title: 'Suffix',
            key: 'member.suffix'
        }, {
            dataIndex: ['member', 'ssn'],
            title: 'SSN',
            key: 'member.ssn'
        }, {
            dataIndex: ['member', 'cardClientNo'],
            title: 'Card #',
            key: 'member.cardClientNo'
        }, {
            dataIndex: ['member', 'dateOfBirth'],
            title: 'Birth Date',
            key: 'member.dateOfBirth',
            render: (dateOfBirth) => {
                return (
                    <div>
                        {dateOfBirth === null || dateOfBirth === '1900-01-01T00:00:00Z' ? '' : Moment(dateWithNoTimezone(dateOfBirth)).format("L")}
                    </div>
                );
            }
        }, {
            dataIndex: ['member', 'status'],
            title: 'Status',
            key: 'member.status'
        }, {
            dataIndex: 'class',
            title: 'Class',
            key: 'class'
        }, {
            dataIndex: 'rateClass',
            title: 'Rate Class',
            key: 'rateClass'
        }, {
            dataIndex: 'apprenticeClass',
            title: 'Apprentice Class',
            key: 'apprenticeClass'
        }, {
            dataIndex: 'trade',
            title: 'Trade',
            key: 'trade'
        }, {
            dataIndex: 'tradeLevel',
            title: 'Trade Level',
            key: 'tradeLevel'
        }, {
            dataIndex: ['member', 'paidthruDate'],
            title: 'Paidthru Date',
            key: 'member.paidthruDate',
            render: (paidthruDate) => {
                return (
                    <div>
                        {paidthruDate === null || paidthruDate === '1900-01-01T00:00:00Z' ? '' : Moment(dateWithNoTimezone(paidthruDate)).format("L")}
                    </div>
                );
            }
        }, {
            title: 'Manage Member',
            key: 'manage',
            width: 120,
            align: 'center',
            render: (text, record) => (
                <span><i id="manageMember" className="fas fa-edit fa-1x ml-3 text-center hover" onClick={(e) => handleEditMember(e, record)}></i></span>
            ),
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function handleEditMember(e, record) {
        navigate("/member-dashboard", {
            state: {
                selectedMemberId: record.member.members_ID, selectedMemberName: record.member.lastName + ', ' + record.member.firstName,
                members: members, filteredMembers: filteredMembers, filteredMatchedMembers: filteredMatchedMembers,
                selectedMemberStatus: state.selectedMemberStatus, searchValue: state.searchValue,
                currentPage: state.currentPage, currentPageSize: state.currentPageSize
            }
        });
    }

    function handleMemberStatusChange(value) {
        //setState({
        //    ...state,
        //    selectedMemberStatus: value,
        //    searchValue: ''
        //});

        var myFilteredMembers = [];
        if (value === 'All') {
            //this is everybody
            setfilteredMembers(members);
            setfilteredMatchedMembers(members);
        }
        else {
            //these are just the select status members
            for (var i = 0, l = members.length; i < l; i++) {
                if (members[i].member.status === value) {
                    myFilteredMembers.push(members[i]);
                }
            }

            setfilteredMembers(myFilteredMembers);
            setfilteredMatchedMembers(myFilteredMembers);
        }

        setState({
            ...state,
            selectedMemberStatus: value,
            searchValue: ''
        });
    }

    function searchMembers(value) {
        let searchMatches = [];
        var currMember;
        var valueCleared = false;
        for (let i = 0; i < filteredMembers.length; i++) {
            currMember = filteredMembers[i].member;

            if (value !== '') {
                if (currMember.lastName.toLowerCase().includes(value.toLowerCase()) ||
                    currMember.firstName.toLowerCase().includes(value.toLowerCase()) ||
                    currMember.ssn.toLowerCase().includes(value.toLowerCase()) ||
                    currMember.cardClientNo.toLowerCase().includes(value.toLowerCase()) ||
                    Moment(dateWithNoTimezone(currMember.dateOfBirth)).format("L") === value) {

                    searchMatches = [...searchMatches, filteredMembers[i]];
                }
            }
            else {
                valueCleared = true;
            }
        }

        setfilteredMatchedMembers(valueCleared ? [...filteredMembers] : [...searchMatches]);
    }

    function searchValueChanged(e) {
        console.log(e.target.value);

        setState({
            ...state,
            searchValue: e.target.value
        });
    }

    function updatePageState(page, pageSize) {
        setState({
            ...state,
            currentPage: page,
            currentPageSize: pageSize
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            Member Manager
                        </h2>

                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleAddMember}>
                            Add Member
                        </button>
                    </div>
                    <br />
                    <div className="form-group col-lg-2">
                        <label className="form-label">Select Member Status</label>
                        <Select className="form-control-custom d-inline-block" onChange={handleMemberStatusChange} style={{ width: '100%' }} placeholder="Select Member Status" value={state.selectedMemberStatus || undefined}
                            options={[
                                { value: 'All', label: 'All' },
                                { value: 'Active', label: 'Active' },
                                { value: 'Deceased', label: 'Deceased' },
                                { value: 'Expelled', label: 'Expelled' },
                                { value: 'Inactive', label: 'Inactive' },
                                { value: 'Military withdrawal', label: 'Military withdrawal' },
                                { value: 'Non member', label: 'Non member' },
                                { value: 'Probationary', label: 'Probationary' },
                                { value: 'Traveler', label: 'Traveler' },
                                { value: 'Withdrawl', label: 'Withdrawl' },
                                { value: 'XR', label: 'XR' },
                            ]}
                        >
                        </Select>
                    </div>
                    <Fragment>
                        <Spin spinning={!state.dataLoaded}>
                            <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                <Alert
                                    message="Loading Members"
                                    description="Please stand by while we retrieve members matching your selection criteria"
                                    type="info"
                                />
                            </div>
                            {state.dataLoaded &&
                                <Fragment>
                                    <Input.Search
                                        style={{ paddingTop: "20px", margin: "0 0 10px 0", width: "50%" }}
                                        placeholder="Search Members by Last Name, First Name, SSN, Card # or Date of Birth..."
                                        enterButton
                                        onSearch={searchMembers}
                                        allowClear
                                        value={state.searchValue}
                                        onChange={searchValueChanged}
                                    />

                                    {filteredMatchedMembers.length === 0 ?
                                        <div style={{ fontSize: '18px', color: 'red' }}>No Members Found Matching Your Search Criteria</div>
                                        :
                                        <Fragment>
                                            <div>
                                                <Table className="custom-ant-selection"
                                                    rowKey={item => item.member.members_ID}
                                                    rowSelection={rowSelection}
                                                    hideSelectionColumn={true}
                                                    bordered
                                                    dataSource={filteredMatchedMembers}
                                                    columns={columns}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        pageSizeOptions: ['10', '25', '50', '100'],
                                                        showTotal: (total, range) => (
                                                            <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                                Showing {range[0]}-{range[1]} of {total}
                                                            </span>
                                                        ),
                                                        onChange: (page, pageSize) => {
                                                            updatePageState(page, pageSize);
                                                        },
                                                        current: state.currentPage,
                                                        pageSize: state.currentPageSize
                                                    }}
                                                    onRow={(record) => {
                                                        return {
                                                            onClick: () => {
                                                                let selRows = [record.member.members_ID];
                                                                setSelectedRowKeys([...selRows]);
                                                            },
                                                            onDoubleClick: (e) => {
                                                                handleEditMember(e, record);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Fragment>
                                    }
                                </Fragment>
                            }
                        </Spin> 

                        {state.showAddNewMemberSlider &&
                            <AddNewMemberSlider selectedMemberId={state.selectedMemberId} showPane={state.showAddNewMemberSlider} members={state.members}
                            updateTableDisplay={updateTableDisplay} updateMembers={updateMembers} hidePane={() => setState({ ...state, showAddNewMemberSlider: false })} />
                        }
                    </Fragment>
                </div>
            </div>
        </div>
    );
}