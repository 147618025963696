import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";
import Globals from '../../config/globals';
import { Modal, message, Spin, Select, Alert, Table } from 'antd';

export default function ClassificationModal(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        showModal: props.showModal,
        classificationType: '',
        classificationEditMode: '',
        modalTitle: '',
        buttonLabel: '',
        itemsList: [],
        memberID: props.memberID,
        listItemsLoaded: false,
        dataLoaded: false
    });

    const dateFormat = 'MM/DD/YYYY';

    useEffect(() => {
        console.log(props);

        SetModalContent();
    }, [props]);

    useEffect(() => {
        if (state.listItemsLoaded === true) {
            if (props.classificationEditMode === 'Edit') {
                setValue('selectedClassificationItem', props.selectedClassificationItem);
            }
            else {
                setValue('selectedClassificationItem', null);
            }
            setValue('classificationType', props.classificationType);

            setValue('formFields.appliesFrom', '');
            setValue('formFields.selectedListItem', '');

            setState({
                ...state,
                dataLoaded: true
            });
        }
    }, [state.listItemsLoaded]);

    function SetModalContent() {
        let myButtonLabel = '';
        let myModalTitle = '';

        if (props.classificationEditMode === 'Edit') {
            myButtonLabel = 'Update ' + props.friendlyClassificationType;
            myModalTitle = 'Update Member ' + props.friendlyClassificationType
        }
        else {
            myButtonLabel = 'Add New ' + props.friendlyClassificationType;
            myModalTitle = 'Add New Member ' + props.friendlyClassificationType
        }

        setState({
            ...state,
            itemsList: [],
            listItemsLoaded: false,
            dataLoaded: false
        });

        Axios.get(`/api/GetClassificationAvailableEntries`, {
            params: {
                classificationType: props.classificationType
            }
        }).then(response => {
            console.log(response.data);

            setState({
                ...state,
                itemsList: response.data,
                modalTitle: myModalTitle,
                buttonLabel: myButtonLabel,
                showModal: props.showModal,
                listItemsLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    function UpdateClassification() {
        let postdata = getValues().selectedClassificationItem;
        postdata.CreatedBy = Globals.userInfo.kmrUserID;
        postdata.ClassificationType = props.classificationType;
        postdata.members_ID = props.memberID;

        if (props.classificationEditMode === 'Edit') {
            Axios.post(`/api/UpdateClassification`, postdata
            ).then(response => {
                /*let newClassification = response.data;*/

                toast.success('Member ' + props.friendlyClassificationType + ' successfully updated');

                props.updateTableDisplay();

                closeModal();

            }).catch(error => {
                toast.error('There was a problem updating Member' + props.friendlyClassificationType);
            });
        }
        else {
            Axios.post(`/api/AddNewClassification`, postdata
            ).then(response => {
                //let newClassification = response.data;

                toast.success('Member ' + props.friendlyClassificationType + ' successfully updated');

                props.updateTableDisplay();

                closeModal();

            }).catch(error => {
                toast.error('There was a problem updating Member' + props.friendlyClassificationType);
            });
        }
    }

    function closeModal() {
        props.disableModal();

        setState({
            ...state,
            showModal: false
        });
    }

    function openModal() {
        setState({
            ...state,
            showModal: true
        });
    }

    function onCompleteRequest() {
        /*console.log(props.classificationType);*/
        switch (props.classificationType) {
            case 'Status':

                break;
            case 'RateClass':
                ProcessAddRateClass();
                break;
            case 'Trade':

                break;
            case 'TradeLevel':

                break;
            default:
            // code block
        }

        ////check to make sure a rate class and effective date have been selected
        //if (getValues().selectedClassificationItem === null || getValues().selectedClassificationItem.itemCode === '' || getValues().selectedClassificationItem.appliesFrom === '' || getValues().selectedClassificationItem.appliesFrom === null) {
        //    toast.error('You must select a ' + props.friendlyClassificationType + ' and an effective date');

        //    return;
        //}

        ////check to make sure the effective date is the first day of the month
        //let myItem = getValues().selectedClassificationItem;
        //if (getValues().classificationType === 'RateClass') {
        //    const d = Moment(dateWithNoTimezone(getValues().selectedClassificationItem.appliesFrom)).toDate();

        //    console.log(d.getDate().toString());

        //    if (d.getDate() !== 1) {
        //        toast.error('Effective Date must be the first date of the month');
        //        return;
        //    }
        //}

        ////get the most recent appliesFrom effective date and make sure the effective date of the new Rate Class is not before that
        ////if it is do not allow
        //let historyItemsList = props.historyItemsList;
        //let newRateClassEffectiveDate = new Date(dateWithNoTimezone(getValues().selectedClassificationItem.appliesFrom));

        //for (i = 0; i < historyItemsList.length; i++) {
        //    if (mostRecentEffectiveDate === undefined) {
        //        mostRecentEffectiveDate = new Date(dateWithNoTimezone(historyItemsList[i].appliesFrom))
        //    }
        //    else {
        //        historyItemDate = new Date(dateWithNoTimezone(historyItemsList[i].appliesFrom))
        //        if (historyItemDate > mostRecentEffectiveDate) {
        //            mostRecentEffectiveDate = historyItemDate;
        //        }
        //    }
        //}
        //if (mostRecentEffectiveDate > newRateClassEffectiveDate) {
        //    toast.error('Effective Date for new rate class cannot be before effective date of prior rate class');
        //    return;
        //}

        //UpdateClassification();
    }

    function dateCheck(from, to, check) {
        var fromDate, toDate, checkDate;
        fromDate = Date.parse(from);
        toDate = Date.parse(to);
        checkDate = Date.parse(check);

        if ((checkDate <= toDate && checkDate >= fromDate)) {
            return true;
        }
        return false;
    }

    function StripTimeFromDate(fullDateTime) {
        let newFullDateTime = new Date(fullDateTime);
        let DateOnly = new Date(newFullDateTime.getFullYear().toString() + '-' + (newFullDateTime.getMonth() + 1).toString() + '-' + newFullDateTime.getDate().toString());

        return DateOnly;
    }

    function GetLastDayOfMonth() {
        var today = new Date();
        var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    }

    function ProcessAddRateClass() {
        var i;
        var j;
        var mostRecentEffectiveDate;
        var historyItemDate;

        //check to make sure a rate class and effective date have been selected
        if (getValues().selectedClassificationItem === null || getValues().selectedClassificationItem.itemCode === '' || getValues().selectedClassificationItem.appliesFrom === '' || getValues().selectedClassificationItem.appliesFrom === null) {
            toast.error('You must select a ' + props.friendlyClassificationType + ' and an effective date');

            return;
        }

        //check to make sure the effective date is the first day of the month
        const d = Moment(dateWithNoTimezone(getValues().selectedClassificationItem.appliesFrom)).toDate();
        if (d.getDate() !== 1) {
            toast.error('Effective Date must be the first date of the month');
            return;
        }

        //get the most recent appliesFrom effective date and make sure the effective date of the new Rate Class is not before that
        //if it is do not allow
        let historyItemsList = props.historyItemsList;
        let newRateClassEffectiveDate = StripTimeFromDate(dateWithNoTimezone(getValues().selectedClassificationItem.appliesFrom));
        //console.log(dateWithNoTimezone(getValues().selectedClassificationItem.appliesFrom));

        for (i = 0; i < historyItemsList.length; i++) {
            if (mostRecentEffectiveDate === undefined) {
                mostRecentEffectiveDate = StripTimeFromDate(dateWithNoTimezone(historyItemsList[i].appliesFrom))
            }
            else {
                historyItemDate = StripTimeFromDate(dateWithNoTimezone(historyItemsList[i].appliesFrom))
                if (historyItemDate > mostRecentEffectiveDate) {
                    mostRecentEffectiveDate = historyItemDate;
                }
            }
        }

        if (mostRecentEffectiveDate > newRateClassEffectiveDate) {
            toast.error('Effective Date for new rate class cannot be before effective date of prior rate class');
            return;
        }

        //now look at existing charges to see if member has paid in advance to determine what rate class charges and credits need to be created, if any
        var chargeFromDate;
        let chargesList = props.chargesList;
        var affectedChargesList = [];

        for (i = 0; i < chargesList.length; i++) {
            for (j = 0; j < chargesList[i].chargesDetailsList.length; j++) {
                chargeFromDate = StripTimeFromDate(dateWithNoTimezone(chargesList[i].chargesDetailsList[j].chargeDateApplicableFrom));

                if (chargeFromDate >= newRateClassEffectiveDate) {
                    let affectedCharge = {
                        memberCharges_ID: chargesList[i].memberCharges_ID,
                        memberChargesDetails_ID: chargesList[i].chargesDetailsList[j].memberChargesDetails_ID,
                        chargeAmount: chargesList[i].chargesDetailsList[j].chargeAmount,
                        chargeDateApplicableFrom: chargesList[i].chargesDetailsList[j].chargeDateApplicableFrom,
                        chargeDateApplicableTo: chargesList[i].chargesDetailsList[j].chargeDateApplicableTo
                    }
                    affectedChargesList.push(affectedCharge);

                    //console.log(chargeFromDate);
                    //console.log(chargesList[i].chargesDetailsList[j].chargeAmount);
                }
            }
        }

/*        console.log(affectedChargesList);*/

        //now, if any payments are affected, go get the new rate class charge amount and apply rate class change charges and credits where applicable
        if (affectedChargesList.length > 0) {
            Axios.get(`/api/GetDuesRateByRateClass`, {
                params: {
                    rateClass: getValues().selectedClassificationItem.itemCode
                }
            }).then(response => {
                let newRateClassAmount = response.data.localAmount;
                console.log(response.data.localAmount);

                for (i = 0; i < affectedChargesList.length; i++) {
                    //console.log(newRateClassAmount);
                    //console.log(affectedChargesList[i].chargeAmount);

                    if (newRateClassAmount > affectedChargesList[i].chargeAmount) {
                        createRateClassCharge(affectedChargesList[i], newRateClassAmount - affectedChargesList[i].chargeAmount);
                    }
                    else if (newRateClassAmount < affectedChargesList[i].chargeAmount) {
                        createRateClassCredit(affectedChargesList[i], affectedChargesList[i].chargeAmount - newRateClassAmount);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }

        //now add the new rate class for the member
        UpdateClassification();
    }

    function createRateClassCharge(affectedCharge, chargeAmount) {
        let postdata = {
            Members_ID: props.memberID,
            MemberCharges_ID: 0,
            FeeID: 58, 
            ChargeDateApplicableFrom: affectedCharge.chargeDateApplicableFrom,
            ChargeDateApplicableTo: affectedCharge.chargeDateApplicableTo,
            ChargeAmount: chargeAmount,
            ChargeAmountLocal: 0.00,
            ChargeAmountInternational: 0.00,
            AdvancePayment: 'N',
            ManualPayment: 'N',
            IsPosted: 0,
            IsVoided: 0,
            CreatedDateTime: new Date(),
            ChargeComments: 'Charge created for rate class change',
            Pending: true
        }

        console.log('A rate class CHARGE of $' + chargeAmount.toString() + ' would be applied');
        console.log(postdata);

        Axios.post(`/api/InsertMemberChargesDetail`, postdata
        ).then(response => {  

        }).catch(error => {
            toast.error('There was a problem inserting MemberChargesDetail');
        });
    }

    function createRateClassCredit(affectedCharge, chargeAmount) {
        let postdata = {
            Members_ID: props.memberID,
            MemberCharges_ID: 0,
            FeeID: 57,
            ChargeDateApplicableFrom: affectedCharge.chargeDateApplicableFrom,
            ChargeDateApplicableTo: affectedCharge.chargeDateApplicableTo,
            ChargeAmount: chargeAmount,
            ChargeAmountLocal: 0.00,
            ChargeAmountInternational: 0.00,
            AdvancePayment: 'N',
            ManualPayment: 'N',
            IsPosted: 0,
            IsVoided: 0,
            CreatedDateTime: new Date(),
            ChargeComments: 'Credit created for rate class change',
            Pending: true
        }

        console.log('A rate class CREDIT of $' + chargeAmount.toString() + ' would be applied');
        console.log(postdata);

        Axios.post(`/api/InsertMemberChargesDetail`, postdata
        ).then(response => {

        }).catch(error => {
            toast.error('There was a problem inserting MemberChargesDetail');
        });
    }

    return (
        <>
            <Modal
                visible={state.showModal}
                title={state.modalTitle}
                onCancel={closeModal}
                footer={[
                    <button className="btn btn-submit" onClick={onCompleteRequest}>
                        {state.buttonLabel}
                    </button>,
                    <span>&nbsp;&nbsp;</span>,
                    <button className="btn btn-outline-primary" onClick={closeModal}>
                        Close
                    </button>
                ]}
            >
                <div className="row">
                    {props.classificationType === 'RateClass' &&
                        <div className="form-group col-lg-12">
                            <label className="form-label mb-0 text-danger">Union Rate Classification Effective Date must be first day of any month.</label>
                        </div>
                    }

                    <div className="form-group col-lg-5">
                        <label className="form-label mb-0">Select New {props.friendlyClassificationType}:</label>
                    </div>
                    <div className="form-group col-lg-7">
                        <select className="form-control-custom" {...register("selectedClassificationItem.itemCode")} value={getValues().selectedClassificationItem?.itemCode}>
                            <option></option>
                            {state.itemsList.map((li, idx) => <option key={idx} value={li.listItemCode}>{li.listItemName}</option>)}
                        </select>
                    </div>

                    <div className="form-group col-lg-5">
                        <label className="form-label mb-0">Effective Date From:</label>
                    </div>
                    <div className="form-group col-lg-4">
                        <Controller
                            name="selectedClassificationItem.appliesFrom"
                            control={control}
                            setValue={setValue}
                            defaultValue={getValues().selectedClassificationItem?.appliesFrom ? Moment(dateWithNoTimezone(getValues().selectedClassificationItem.appliesFrom)).toDate() : null}
                            render={() =>
                                <DatePicker
                                    selected={getValues().selectedClassificationItem?.appliesFrom ? Moment(dateWithNoTimezone(getValues().selectedClassificationItem.appliesFrom)).toDate() : null}
                                    onChange={date => setValue('selectedClassificationItem.appliesFrom', date)}
                                    className="form-control-custom"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="MM/dd/yyyy"
                                />
                            }
                        />
                    </div>
                    {(props.classificationType !== 'Status' && props.classificationType !== 'RateClass') &&
                        <Fragment>
                            <div className="form-group col-lg-5">
                                <label className="form-label mb-0">Expiration Date:</label>
                            </div>
                            <div className="form-group col-lg-4">
                                <Controller
                                    name="selectedClassificationItem.appliesTo"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().selectedClassificationItem?.appliesTo ? Moment(dateWithNoTimezone(getValues().selectedClassificationItem.appliesTo)).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().selectedClassificationItem?.appliesTo ? Moment(dateWithNoTimezone(getValues().selectedClassificationItem.appliesTo)).toDate() : null}
                                            onChange={date => setValue('selectedClassificationItem.appliesTo', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="MM/dd/yyyy"
                                        />
                                    }
                                />
                            </div>
                        </Fragment>
                    }
                </div>
            </Modal>
        </>
    );
}