import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import _ from "lodash";

export const highlightContent = (name, inputValue) => {
    const re = new RegExp(_.escapeRegExp(inputValue), "i");

    if (re.test(name)) {
        const m = match(name, inputValue, { insideWords: true });
        return parse(name, m);
    }
}