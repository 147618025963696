import React, { useEffect, useState } from 'react';
import logo from '../../img/logo.png';
import './navigation.css';
import { Popover, Select } from 'antd';
import Helmet from 'react-helmet';
import CustomSearch from './CustomSearch';
import { confirm } from '@rickylandino/react-messages';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import { activeRoute } from '../../services/SpaService';
import { takeWhile } from 'rxjs/operators';
import MediaQuery from 'react-responsive'
import { FaUsers } from "react-icons/fa";
import { FaFileSignature } from "react-icons/fa";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FaChartBar } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { sendResetPasswordLink, userInfo, userPermissions } from '../../services/UsersService';
import Globals from '../../config/globals';

//import ReportsSlider from '../ReportingOutput/ReportsSlider';

export function TopNav(props) {
    const navigate = useNavigate();

    const [state, setState] = useState({
        showReportsSlider: false
    });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        var alive = true;

        activeRoute.pipe(takeWhile(() => alive)).subscribe((route) => {
            switch (route) {
                case '/dashboard':
                    $(".header-icon").removeClass("active");
                    $("#dashboardBtn").addClass("active");
                    break;
                case '/contractor-manager':
                    $(".header-icon").removeClass("active");
                    $("#contractorManagerBtn").addClass("active");
                    break;
                case '/manage-contractor-requests':
                case '/mr-old-request':
                case '/archive-requests':
                    $(".header-icon").removeClass("active");
                    $("#marketRecoveryRequests").addClass("active");
                    break;
                case '/job-manager':
                    $(".header-icon").removeClass("active");
                    $("#jobManagerBtn").addClass("active");
                    break;
                case '/process-payments':
                    $(".header-icon").removeClass("active");
                    $("#processPaymentsBtn").addClass("active");
                    break;
                case '/utilities':
                case '/data-cleanup':
                    $(".header-icon").removeClass("active");
                    $("#utilities").addClass("active");
                    break;
                case '/user-manager':
                case '/role-template-manager':
                case '/menu-manager':
                    $(".header-icon").removeClass("active");
                    $("#admin").addClass("active");
                    break;

                default:
                    $(".header-icon").removeClass("active");
                    break;
            }
        });
    }, [props]);

    function handleNavigation(event) {
        event.preventDefault();

        switch (event.target.id) {
            case 'dashboardBtn':
                $(".listHeader").removeClass("active open");
                navigate("/dashboard", { replace: true });
                break;
            case 'memberManagerBtn':
                $(".listHeader").removeClass("active open");
                navigate("/member-manager", { replace: true });
                break;
            case 'reportingBtn':
                setState({
                    ...state,
                    showReportsSlider: true
                });
                break;
            default:
                break;
        }

        $('.subTabs').parent('li').removeClass('active');
        $('#' + event.target.id).parent('li').addClass('active');
    }

    function handleLogout(event) {
        confirm({
            title: "You are about to logout",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                event.preventDefault();

                navigate("/", { replace: true });

                return 0;
            } else {
                return 0;
            }
        });
    }

    const { Option } = Select;
    return (
        <nav className="navbar navbar-expand-xl navbar-light bg-header flex-column flex-md-row bd-navbar">
            <div className="navbar-brand hover">
                <img alt="Local777" id="dashboardBtn" onClick={handleNavigation} src={logo} style={{ height: '75px' }} />
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-toggle" aria-controls="navbar-toggle" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbar-toggle">
                <ul className="navbar-nav mt-2 mt-lg-0 me-auto">
                    {Globals.userInfo?.userType === 'Admin' &&
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <div>
                                <span className="header-icon mimicA top-nav-item" id="memberManagerBtn" onClick={handleNavigation}>
                                    <MediaQuery minWidth={1570}>
                                        <FaUsers className="text-icon hideable fa-2x" id="memberManagerBtn" onClick={handleNavigation} /><i className="fa-solid fa-helmet-safety fa-1x text-icon hideable" id="memberManagerBtn" onClick={handleNavigation} /> <span className="top-nav-item-desc" id="memberManagerBtn" onClick={handleNavigation}>Members</span>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={1569}>
                                        <span className="top-nav-item-desc" id="memberManagerBtn" onClick={handleNavigation}>Members</span>
                                    </MediaQuery>
                                </span>
                            </div>
                        </li>
                    }

                    {Globals.userInfo?.userType === 'Admin' &&
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <div>
                                <span className="header-icon mimicA top-nav-item" id="reportingBtn" onClick={handleNavigation}>
                                    <MediaQuery minWidth={1570}>
                                        <FaChartBar className="fa-1x text-icon hideable" /> <span className="top-nav-item-desc" id="reportingBtn" onClick={handleNavigation}>Reporting/Output</span>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={1569}>
                                        <span className="top-nav-item-desc" id="" id="reportingBtn" onClick={handleNavigation}>Reporting</span>
                                    </MediaQuery>
                                </span>
                            </div>
                        </li>
                    }
                    <div className="current-user ms-auto d-flex align-items-center">Welcome: {userInfo.fullName}</div>
                    <div className="search-div full-size">
                        
                        <CustomSearch />
                    </div>

                    {userInfo?.userType === 'Admin' &&
                        <li className="nav-item">
                            <div className="dropdown">
                                <span className="header-icon mimicA top-nav-item" id="utilities">
                                    <i className="fas fa-tools fa-1x text-icon"></i>
                                </span>
                                <div className="dropdown-content" style={{ right: '0' }}>
                                    <div id="userManagerBtn" onClick={handleNavigation} data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <span className="subTabs waves-effect waves-themed active" id="userManagerBtn" onClick={handleNavigation}>
                                            Manage Users
                                        </span>
                                    </div>
                                    <div id="checkUtilitiesBtn" onClick={handleNavigation} data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <span className="subTabs waves-effect waves-themed active" id="checkUtilitiesBtn" onClick={handleNavigation}>
                                            Check Utilities
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    }

                    <div className="seperator">
                    </div>

                    <div>
                        <span className="header-icon mimicA top-nav-item" onClick={handleLogout}>
                            <i className="fas fa-sign-out-alt logoutIcon" onClick={handleLogout} />
                        </span>
                    </div>
                </ul>
            </div>

        </nav>
    );
}