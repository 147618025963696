import React from 'react';

class Globals extends React.Component {

    static userInfo = {
        userId: "",
        username: "",
        fullName: "",
        userType: "",
        isLoggedIn: false,
        bearer: "",
        kmrUserId: ""
    };

    static bearer = '';
    static environmentToDisplay = '';

    static mobileWidth = 1424;
    static xlScreen = 1600;

}

export default Globals;
